import AxiosStatic, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { configure as configureAxios } from "axios-hooks";
import { jwtDecode } from "jwt-decode";
import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { buildParams } from "../util/utils";

interface JWTToken {
  sub: string;
  email: string;
  iss: string;
  jti: string;
  iat: number;
  exp: number;
}

interface ContextInterface {
  token: string | null;
  login: (token: string) => void;
  logout: () => void;
  decodedToken: JWTToken | null;
  isAuthenticated: boolean;
  axios: AxiosInstance;
}

export interface TokenResponse {
  token: string;
  refreshToken: string;
}

const AuthContext = createContext<ContextInterface>({
  login: () => {},
  logout: () => {},
  token: null,
  decodedToken: null,
  isAuthenticated: false,
  axios: AxiosStatic.create(),
});

export const AuthenticationProvider: FC = ({ children }) => {
  const navigate = useNavigate();
  const storedToken = window.localStorage.getItem("token");

  const [token, setToken] = useState(storedToken);
  const [decodedToken, setDecodedToken] = useState<any>();
  const [isAuthenticated, setIsAuthenticated] = useState(!!storedToken);
  const { i18n } = useTranslation(undefined, { useSuspense: false });
  const [axiosConfig, setAxiosConfig] = useState<AxiosRequestConfig>();

  const defaultAxiosConfig = useMemo<AxiosRequestConfig>(() => {
    return {
      headers: {
        "Accept-Language": i18n.language,
      },
      baseURL: process.env.REACT_APP_MONITR_MAIN_BASE_URI,
      paramsSerializer: params => {
        // Strip undefined values from params
        const strippedParams = Object.keys(params).reduce(
          (acc, key) => (params[key] === undefined ? { ...acc } : { ...acc, [key]: params[key] }),
          {},
        );
        return buildParams(strippedParams);
      },
    };
  }, [i18n.language]);

  function login(token: string) {
    setToken(token);
  }

  function logout() {
    setToken(null);
  }

  function _logout() {
    window.localStorage.removeItem("token");
    setIsAuthenticated(false);
  }

  // TODO: Add refresh token logic here
  const tokenExpirationInterceptor = useCallback(error => {
    if (
      error instanceof AxiosError &&
      error.response?.status === 401 &&
      error.response.data.error === "Unauthorized" &&
      error.response.data.message.includes("Authorization token expired") // A bit brittle, need a decent error code for this
    ) {
      _logout();
    }

    return Promise.reject(error);
  }, []);

  const authorizationInterceptor = useCallback(
    error => {
      if (
        error instanceof AxiosError &&
        error.response?.status === 402 &&
        error.response.data.error === "Payment Required"
      ) {
        navigate("/dashboard/organisations/billing");
      }
      return Promise.reject(error);
    },
    [navigate],
  );

  useEffect(() => {
    let axiosConfig = defaultAxiosConfig;

    if (token) {
      const decodedToken = jwtDecode<JWTToken>(token);

      // Check token expiration
      if (decodedToken.exp <= Math.floor(+new Date() / 1000)) {
        _logout();
        return;
      }

      setDecodedToken(decodedToken);

      // Set Axios instance with a default authorization header
      axiosConfig = {
        ...defaultAxiosConfig,
        headers: {
          ...defaultAxiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      };

      window.localStorage.setItem("token", token);
      setIsAuthenticated(!!(token && decodedToken));
    } else if (token == null) {
      _logout();
    }

    /**
     * Set Axios instance
     */
    AxiosStatic.defaults.baseURL = axiosConfig.baseURL; // Global axios default baseURL
    AxiosStatic.defaults.headers.common = axiosConfig.headers ?? {}; // Global axios headers
    const axios = AxiosStatic.create(axiosConfig);
    axios.interceptors.response.use(undefined, tokenExpirationInterceptor); // Token expiration interceptor
    axios.interceptors.response.use(undefined, authorizationInterceptor); // Authorization interceptor
    configureAxios({ axios, cache: false });
    setAxiosConfig(axiosConfig);
  }, [token, i18n.language, tokenExpirationInterceptor, authorizationInterceptor, defaultAxiosConfig]);

  const defaultContext: ContextInterface = {
    token,
    login,
    logout,
    decodedToken,
    isAuthenticated,
    axios: AxiosStatic.create(axiosConfig),
  };

  return <AuthContext.Provider value={defaultContext}>{children}</AuthContext.Provider>;
};

export function useAuthentication(): ContextInterface {
  return useContext(AuthContext);
}
