import { Integration } from "../../../types/integration";

export enum ScenarioBudgetType {
  Budget = "budget",
  PersonnelCosts = "personnel_costs",
  RecurringRevenue = "recurring_revenue",
  ProjectRevenue = "project_revenue",
  PxQ = "pxq",
  COGS = "cogs",
  Inventory = "inventory",
}

export enum GlobalBudgetType {
  VAT = "vat",
  OutstandingItems = "outstanding_items",
  CRM = "crm",
}

export const BudgetType = { ...ScenarioBudgetType, ...GlobalBudgetType };
// eslint-disable-next-line
export type BudgetType = ScenarioBudgetType | GlobalBudgetType;

export interface BudgetVersion {
  id: string;
  name: string;
  budget_type: BudgetType;
  integration: Integration | null;
  settings: {
    startDate?: string;
    endDate?: string;
  } & Record<string, unknown>;
  created_at: string;
}
