import { BasicReport } from "../../../types/reports";

export enum CustomMetricType {
  Bank = "bank",
  NetResult = "net_result",
  Revenue = "revenue",
  TotalBalanceSheet = "total_balance_sheet",
  SalesAndMarkeringCosts = "sales_and_marketing_costs",
  ResultCarriedForward = "result_carried_forward",
  CashAndBank = "cash_and_bank",
  Equity = "equity",
  FxRateDifferenceBS = "fx_rate_difference_bs",
  FxRateDifferencePL = "fx_rate_difference_pl",
}

export interface CustomMetricDefault {
  id: string;
  customMetricType: CustomMetricType;
  reportingLineId: string | null;
  default: boolean;
  mandatory: boolean;
  report: BasicReport;
}

export interface PutCustomMetric {
  id: string;
  customMetricType: CustomMetricType;
  reportingLineId: string | null;
}
