import { Tier, TiersByRevenue } from "./types";

export const tiers: Tier[] = [
  { lowerLimit: Number.NEGATIVE_INFINITY, upperLimit: 20000, name: "0 - 20K Revenue" },
  { lowerLimit: 20000, upperLimit: 40000, name: "20 - 40K Revenue" },
  { lowerLimit: 40000, upperLimit: 80000, name: "40 - 80K Revenue" },
  { lowerLimit: 80000, upperLimit: 200000, name: "80 - 200K Revenue" },
  { lowerLimit: 200000, name: "+200K Revenue" },
];

export function getCurrentAndNextTierByAvgRevenue(avgRevenue: number): TiersByRevenue {
  const tierIndex = tiers.findIndex(
    tier => tier.lowerLimit <= avgRevenue && (tier.upperLimit === undefined || avgRevenue < tier.upperLimit),
  );
  if (tierIndex === -1) throw Error("No tier found by average revenue");
  return {
    currentTier: tiers[tierIndex],
    nextTier: tierIndex !== 4 ? tiers[tierIndex + 1] : undefined,
  };
}
