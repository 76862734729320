import { Spinner, Stack, useTheme } from "@fluentui/react";
import { AxisBottom, AxisLeft, TickRendererProps } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Bar, Line } from "@visx/shape";
import { Text } from "@visx/text";
import { NumberValue } from "d3-scale";
import moment from "moment";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Entity, EntityWithPermissions } from "../../../../../../../types/entity";
import { calculateCardinality, formatCentToDecimal } from "../../../../../../../util/numbers";
import { CustomMetricType } from "../../../../../../hooks/custom-metrics/types";
import { useCustomMetrics } from "../../../../../../hooks/custom-metrics/useCustomMetrics";
import { useReports } from "../../../../../../hooks/useReports";
import { ReportResponse } from "../../../../../../hooks/useReports/types";
import EmptyState from "../../../../../../molecules/EmptyState";
import {
  GraphTitle,
  dateTick,
  defaultAxisTickLabelProps,
  formatValue,
  strokeWidth,
} from "../../../../../../organisms/Graphs/Base/base";

import { RevenuePerPeriod, TiersByRevenue } from "./types";
import { getCurrentAndNextTierByAvgRevenue, tiers } from "./util";

interface Props {
  entity: Entity & EntityWithPermissions;
}

const EntityRevenueGraph: FC<Props> = ({ entity }) => {
  const theme = useTheme();
  const { t } = useTranslation(["billing"]);
  const threeMonthsAgo = moment().subtract(3, "months");
  const endDate =
    entity.last_date_actuals === null ? threeMonthsAgo : moment.max(moment(entity.last_date_actuals), threeMonthsAgo);

  const { customMetrics, loading: cmLoading } = useCustomMetrics({ entityId: entity.id });
  const revenueLine = useMemo(
    () =>
      customMetrics?.find(cm => cm.customMetricType === CustomMetricType.Revenue && cm.default && cm.mandatory)
        ?.reportingLineId ?? undefined,
    [customMetrics],
  );

  const { data: monthlyRevenue, loading: mrLoading } = useReports<ReportResponse>({
    url: `/entities/${entity.id}/reports/PL/actuals`,
    queryString: {
      from: endDate.clone().subtract(1, "year").add(1, "month").startOf("month").format("YYYY-MM-DD"),
      lda: endDate.endOf("month").format("YYYY-MM-DD"),
      to: endDate.endOf("month").format("YYYY-MM-DD"),
      aggregationValue: "month",
      aggregationType: "period",
      reportingLineIds: revenueLine ? [revenueLine] : undefined,
      cardinalityType: "value",
      cardinalityValue: 1,
    },
    manual: revenueLine === undefined,
  });

  const loading = cmLoading || mrLoading;
  const [revenuePerMonth, isCrossYear, avgRevenue, tiersByRevenue] = useMemo<
    [RevenuePerPeriod[], boolean, number, TiersByRevenue]
  >(() => {
    if (monthlyRevenue === undefined || monthlyRevenue.data.length === 0)
      return [[], false, 0, { currentTier: tiers[0] }];
    const [revenuePerPeriod, minDate, maxDate] = Object.keys(monthlyRevenue.data[0].columns).reduce<
      [RevenuePerPeriod[], moment.Moment, moment.Moment]
    >(
      (res, date) => {
        const period = moment(date, monthlyRevenue.meta.columnFormat);
        res[0].push({
          period: period.startOf("month").format("YYYY-MM-DD"),
          revenue: formatCentToDecimal(monthlyRevenue.data[0].columns[date] as string),
        });
        res[1] = moment.min(res[1], period);
        res[2] = moment.max(res[2], period);
        return res;
      },
      [[], moment("2552-09-16"), moment("1970-01-01")],
    );
    const isCrossYear = !minDate.isSame(maxDate, "year");
    const nonZeroRevenue = revenuePerPeriod.filter(rpp => rpp.revenue !== 0);
    const avgRevenue =
      nonZeroRevenue.reduce<number>((res, rev) => res + rev.revenue, 0) /
      (nonZeroRevenue.length === 0 ? 1 : nonZeroRevenue.length);
    const tiersByRevenue = getCurrentAndNextTierByAvgRevenue(avgRevenue);
    return [revenuePerPeriod, isCrossYear, avgRevenue, tiersByRevenue];
  }, [monthlyRevenue]);

  const avgRevenueColor = "#644DF6";
  const tierColor = "#1486FC";
  const margin = { top: 10, right: 30, bottom: isCrossYear ? 40 : 30, left: 60 };
  // scales
  const dateScale = scaleBand<string>({
    domain: revenuePerMonth.map(d => d.period),
    padding: 0.2,
  });
  const totalsScale = scaleLinear<number>({
    domain: [
      Math.max(Math.min(...revenuePerMonth.map(d => d.revenue), tiersByRevenue.currentTier.lowerLimit), 0),
      Math.max(...revenuePerMonth.map(d => d.revenue), tiersByRevenue.nextTier?.lowerLimit ?? 0),
    ],
    nice: true,
  });

  const cardinality = calculateCardinality(totalsScale.domain()[1]);
  const axisWithMantissaBar = totalsScale.domain()[0] > -10 && totalsScale.domain()[1] < 10;

  return (
    <Stack
      styles={{
        root: {
          backgroundColor: theme.palette.neutralLighterAlt,
          paddingBottom: 10,
          paddingLeft: 10,
          height: "100%",
          position: "relative",
        },
      }}
    >
      {loading && <Spinner styles={{ root: { position: "absolute", top: 5, left: 5 } }} />}
      {revenuePerMonth.length === 0 ? (
        !loading && (
          <EmptyState
            title={t("subscriptionBreakdown.graph.emptyState.title", { ns: "billing" })}
            explainer={t("subscriptionBreakdown.graph.emptyState.explainer", { ns: "billing" })}
          />
        )
      ) : (
        <>
          <GraphTitle>{t("subscriptionBreakdown.graph.title", { ns: "billing" })}</GraphTitle>
          <ParentSize debounceTime={16} parentSizeStyles={{ height: 300 }}>
            {function TheGraph(parent) {
              if (parent.width === 0 || parent.height === 0) return;

              // bounds
              const xMax = parent.width - margin.left - margin.right;
              const yMax = parent.height - margin.top - margin.bottom;

              dateScale.rangeRound([0, xMax]);
              totalsScale.range([yMax, 0]);

              return (
                <svg width={parent.width} height={parent.height}>
                  <GridRows
                    top={margin.top}
                    left={margin.left}
                    scale={totalsScale}
                    width={xMax}
                    height={yMax}
                    stroke={theme.palette.neutralLight}
                    strokeWidth={1}
                  />
                  <AxisBottom
                    top={yMax + margin.top}
                    left={margin.left}
                    scale={dateScale}
                    tickComponent={(tickRendererProps: TickRendererProps) => dateTick(tickRendererProps, isCrossYear)}
                    stroke={theme.palette.neutralDark}
                    tickStroke={"transparent"}
                    tickLength={parseInt(theme.spacing.s2)}
                    tickLabelProps={() => ({
                      ...defaultAxisTickLabelProps,
                      textAnchor: "middle",
                    })}
                  />
                  <AxisLeft
                    top={margin.top}
                    left={margin.left}
                    scale={totalsScale}
                    stroke={theme.palette.neutralDark}
                    tickStroke={"transparent"}
                    tickLength={parseInt(theme.spacing.s2)}
                    tickLabelProps={() => ({
                      ...defaultAxisTickLabelProps,
                      textAnchor: "end",
                      dx: "-5px",
                    })}
                    tickFormat={(val: NumberValue) => formatValue(val, cardinality, axisWithMantissaBar)}
                    hideZero={true}
                    label={t("subscriptionBreakdown.graph.yAxis", {
                      ns: "billing",
                      cardinality,
                      currency: entity.currency,
                    })}
                    numTicks={5}
                    labelProps={{
                      fill: theme.palette.neutralDark,
                      fontSize: theme.fonts.smallPlus.fontSize,
                      textAnchor: "middle",
                      dx: "-5px",
                    }}
                  />
                  <Group top={margin.top} left={margin.left}>
                    {revenuePerMonth.map(d => {
                      const barWidth = dateScale.bandwidth();
                      const barHeight = yMax - (totalsScale(d.revenue) ?? 0);
                      const barX = dateScale(d.period);
                      const barY = yMax - barHeight;
                      return (
                        <Bar
                          key={`bar-${d.period}`}
                          x={barX}
                          y={barY}
                          width={barWidth}
                          height={barHeight}
                          fill={"#EEC762"}
                        />
                      );
                    })}
                    <Group>
                      <Text
                        style={{ fontSize: theme.fonts.medium.fontSize, fontWeight: 600 }}
                        x={0}
                        dx={10}
                        y={totalsScale(tiersByRevenue.currentTier.lowerLimit)}
                        dy={-8}
                        fill={tierColor}
                        textAnchor="start"
                      >
                        {t("subscriptionBreakdown.graph.currentTier", {
                          ns: "billing",
                          name: tiersByRevenue.currentTier.name,
                        })}
                      </Text>
                      <Line
                        from={{ x: 0, y: totalsScale(tiersByRevenue.currentTier.lowerLimit) }}
                        to={{ x: xMax, y: totalsScale(tiersByRevenue.currentTier.lowerLimit) }}
                        stroke={tierColor}
                        strokeWidth={strokeWidth}
                        pointerEvents="none"
                      />
                    </Group>
                    {tiersByRevenue.nextTier !== undefined && (
                      <Group>
                        <Text
                          style={{ fontSize: theme.fonts.medium.fontSize, fontWeight: 600 }}
                          x={0}
                          dx={10}
                          y={totalsScale(tiersByRevenue.nextTier.lowerLimit)}
                          dy={-8}
                          fill={tierColor}
                          textAnchor="start"
                        >
                          {t("subscriptionBreakdown.graph.nextTier", {
                            ns: "billing",
                            name: tiersByRevenue.nextTier.name,
                          })}
                        </Text>
                        <Line
                          from={{ x: 0, y: totalsScale(tiersByRevenue.nextTier.lowerLimit) }}
                          to={{ x: xMax, y: totalsScale(tiersByRevenue.nextTier.lowerLimit) }}
                          stroke={tierColor}
                          strokeWidth={strokeWidth}
                          pointerEvents="none"
                        />
                      </Group>
                    )}
                    <Group>
                      <Text
                        style={{ fontSize: theme.fonts.medium.fontSize, fontWeight: 600 }}
                        x={xMax}
                        y={totalsScale(avgRevenue)}
                        dx={-10}
                        dy={-8}
                        fill={avgRevenueColor}
                        textAnchor="end"
                      >
                        {t("subscriptionBreakdown.graph.avgRevenueLabel", {
                          ns: "billing",
                        })}
                      </Text>
                      <Line
                        from={{ x: 0, y: totalsScale(avgRevenue) }}
                        to={{ x: xMax, y: totalsScale(avgRevenue) }}
                        stroke={avgRevenueColor}
                        strokeWidth={strokeWidth}
                        pointerEvents="none"
                      />
                    </Group>
                  </Group>
                </svg>
              );
            }}
          </ParentSize>
        </>
      )}
    </Stack>
  );
};

export default EntityRevenueGraph;
