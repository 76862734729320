import useAxios from "axios-hooks";

import { User } from "./types";

export const useProfile = () => {
  const [{ data, loading, error }, getProfile] = useAxios<User>("/users/profile");

  if (error !== null) throw error;

  return {
    user: data,
    loading,
    getProfile,
  };
};
