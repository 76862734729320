interface OpenWindowOptions {
  width?: number;
  height?: number;
}

export const minWindowWidth = 900;

export function openWindow(path: string, options: OpenWindowOptions, callback: (data?: any) => void): void {
  const width = options.width || 800;
  const height = options.height || 600;
  const y = window.top!.outerHeight / 2 + window.top!.screenY - height / 2;
  const x = window.top!.outerWidth / 2 + window.top!.screenX - width / 2;

  const messageListener = function (this: Window, event: WindowEventMap["message"]) {
    // Check event origin to make sure it's for us
    if (event.origin !== process.env.REACT_APP_MONITR_MAIN_BASE_URI) {
      console.error("Domains do not match, security issue", event);
      return;
    }

    window.removeEventListener("message", messageListener); // Remove message event listener
    return callback(event.data);
  };

  window.addEventListener("message", messageListener);

  const oWindow = window.open(path, "_blank", `location=0,status=0,width=${width},height=${height},top=${y},left=${x}`);
  if (!oWindow) throw new Error("Could not open window");

  // Setinterval is used to clean up after opened window has been closed
  const interval = window.setInterval(() => {
    if (oWindow.closed) {
      window.clearInterval(interval);
      window.removeEventListener("message", messageListener);
      return callback();
    }
  }, 2000);
}

export function buildParams(data: object) {
  const params = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    if (value === undefined) return;
    if (Array.isArray(value)) {
      value.forEach(value => params.append(key, value.toString()));
    } else {
      params.append(key, value.toString());
    }
  });

  return params.toString();
}

export function sortByKeys<T>(array: Array<T>, order: Array<any>, key: keyof T): Array<T> {
  const map = order.reduce((prev, cur, i) => {
    prev[cur] = i;
    return prev;
  }, {});
  return array.sort((a, b) => map[a[key]] - map[b[key]]);
}

export function assertUnreachable(_x: never): never {
  throw new Error("unreachable");
}

export function keysToObject<K extends string, T>(keys: readonly K[], value: T | ((key: K) => T)): Record<K, T> {
  return Object.fromEntries(keys.map(k => [k, value instanceof Function ? value(k) : value])) as Record<K, T>;
}

// Regex explained: https://regexr.com/58j0k
const propertySplitterRegex = new RegExp(/([^[.\]])+/g);

export function get(obj: Record<string, any>, path?: string): any {
  if (path === undefined) {
    return undefined;
  }
  const pathArray = path.match(propertySplitterRegex);
  if (pathArray === null) return undefined;

  const result = pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj);
  return result;
}

export function objectsToMap<T, U>(objects: T[], field: keyof T): Map<U, T> {
  return objects.reduce<Map<U, T>>((res, curr) => {
    res.set(curr[field] as U, curr);
    return res;
  }, new Map());
}
