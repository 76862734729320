import { IColumn, IDetailsFooterProps, IconButton, Persona, Stack, Text, useTheme } from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import axios from "axios";
import useAxios from "axios-hooks";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDashboardContext } from "../../../../../../context/DashboardContext";
import { SubscriptionTier } from "../../../../../../types/organisation";
import DisableableTooltip from "../../../../../atoms/DisableableTooltip";
import SubscriptionButton from "../../../../../molecules/SubscriptionButton";
import UniformDetailsList from "../../../../../molecules/UniformDetailsList";
import { DeleteOrganisationBody, DeleteOrganisationResponse } from "../types";

import AddOrganisationModal from "./AddOrganisationModal";
import { PaidOrganisation } from "./types";

const PaymentExchange: FC = () => {
  const { t } = useTranslation(["billing", "common"]);
  const theme = useTheme();
  const { selectedOrganisation, organisations, refetchOrganisations } = useDashboardContext();
  const organisation = organisations.find(o => o.id === selectedOrganisation);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const paymentIsOk =
    organisation === undefined ? false : ["trialing", "active"].includes(organisation.subscriptionHolder.paymentState);

  const [
    { error: updateOrganisationSubscriptionHolderError, loading: updateOrganisationSubscriptionHolderLoading },
    updateOrganisationSubscriptionHolder,
  ] = useAxios<DeleteOrganisationResponse, DeleteOrganisationBody>(
    {
      method: "PUT",
    },
    { manual: true },
  );
  if (organisation === undefined) return null;

  const columns: IColumn[] = [
    {
      key: "info",
      name: t("organisation", { ns: "common" }),
      minWidth: 200,
      onRender: (item?: PaidOrganisation) => {
        if (item === undefined) return null;
        return <Persona text={item.name} secondaryText={item.vatNumber} />;
      },
    },
    {
      key: "manage-subscription",
      name: t("paymentExchange.manage", { ns: "billing" }),
      minWidth: 150,
      onRender: (item?: PaidOrganisation) => {
        if (item === undefined) return null;
        return (
          <SubscriptionButton
            text={
              item.tier === SubscriptionTier.Premium
                ? t("paymentStatus.downgrade", { ns: "billing" })
                : t("paymentStatus.upgrade", { ns: "billing" })
            }
            iconName={item.tier === SubscriptionTier.Lite ? "Diamond" : "Down"}
            tier={item.tier}
            onClick={async () => {
              await axios.patch(`/organisations/${item?.id}/monitr-subscription`, {
                tier: item.tier === SubscriptionTier.Lite ? SubscriptionTier.Premium : SubscriptionTier.Lite,
              });
              await refetchOrganisations();
            }}
            ignoreAreYouSure={item.subscriptionHolder.paymentState === "trialing"}
            styles={{
              root: { width: "100%", fontSize: theme.fonts.xSmall.fontSize },
              icon: {
                height: theme.fonts.small.fontSize,
                lineHeight: theme.fonts.small.fontSize,
                fontSize: theme.fonts.small.fontSize,
              },
            }}
          />
        );
      },
    },
    {
      key: "delete",
      name: "",
      minWidth: 32,
      maxWidth: 32,
      onRender: (item?: PaidOrganisation) => {
        if (item === undefined) return null;
        return (
          <IconButton
            iconProps={{ iconName: "Delete" }}
            styles={{
              root: { color: theme.palette.red },
              rootHovered: { color: theme.palette.red, backgroundColor: theme.palette.neutralLight },
            }}
            disabled={updateOrganisationSubscriptionHolderLoading}
            onClick={async () => {
              await updateOrganisationSubscriptionHolder({
                url: `/organisations/${item.id}/organisations-subscription-holder`,
                data: { orgId: item.id },
              });
              await refetchOrganisations();
            }}
          />
        );
      },
    },
  ];
  const currentPaidForOrganisations = organisations.filter(
    org => org.subscriptionHolder.organisationId === organisation.id && org.id !== organisation.id,
  );
  const payingOrganisations = organisations.reduce<Set<string>>((res, org) => {
    if (org.subscriptionHolder.organisationId !== org.id) res.add(org.subscriptionHolder.organisationId);
    return res;
  }, new Set());
  const organisationsToAdd = organisations.filter(
    org =>
      // Can't add yourself
      org.id !== organisation.id &&
      // You need write rights
      org.permissions.includes("organisation.organisation_data:write") &&
      // Organisation can't already being paid for
      org.subscriptionHolder.organisationId === org.id &&
      // Organisation can't be paying for someone else
      !payingOrganisations.has(org.id),
  );

  if (updateOrganisationSubscriptionHolderError) throw updateOrganisationSubscriptionHolderError;

  function onRenderDetailsFooter(detailsFooterProps?: IDetailsFooterProps) {
    if (!detailsFooterProps) return null;
    return (
      <Stack
        styles={{ root: { width: "100%", backgroundColor: theme.palette.neutralLighterAlt } }}
        horizontalAlign="center"
      >
        <DisableableTooltip
          disabled={paymentIsOk}
          content={t("paymentExchange.addOrganisationDisabledTooltip", { ns: "billing" })}
        >
          <IconButton
            iconProps={{ iconName: "Add" }}
            onClick={() => setModalIsOpen(true)}
            styles={{ root: { width: "100%" } }}
            disabled={!paymentIsOk}
          />
        </DisableableTooltip>
      </Stack>
    );
  }

  return (
    <Card tokens={{ padding: theme.spacing.l1 }} styles={{ root: { maxWidth: "100%", width: "100%" } }}>
      <Card.Item>
        <Stack>
          <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
            <Text variant="xLarge">{t("paymentExchange.title", { ns: "billing" })}</Text>
            <Text variant="medium">{t("paymentExchange.explainer", { ns: "billing" })}</Text>
          </Stack>
          <Stack
            styles={{ root: { flex: 1, minHeight: 0, overflowY: "auto", width: "100%" } }}
            data-is-scrollable="true"
          >
            <UniformDetailsList
              key={`paying-for-organisations-list-${currentPaidForOrganisations.length}`}
              useNativeSticky
              items={currentPaidForOrganisations}
              columns={columns}
              shimmerLines={3}
              onRenderDetailsFooter={onRenderDetailsFooter}
            />
          </Stack>
        </Stack>
        {modalIsOpen && (
          <AddOrganisationModal
            onAdd={async orgIds => {
              await Promise.all(
                orgIds.map(id =>
                  updateOrganisationSubscriptionHolder({
                    url: `/organisations/${id}/organisations-subscription-holder`,
                    data: { orgId: organisation.id },
                  }),
                ),
              );
              await refetchOrganisations();
              setModalIsOpen(false);
            }}
            onDismiss={() => setModalIsOpen(false)}
            organisations={organisationsToAdd}
          />
        )}
      </Card.Item>
    </Card>
  );
};

export default PaymentExchange;
