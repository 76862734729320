import { Image, Stack, Text, useTheme } from "@fluentui/react";
import axios from "axios";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useDashboardContext } from "../../../../../context/DashboardContext";
import { SubscriptionTier } from "../../../../../types/organisation";
import useStripe from "../../../../hooks/useStripe";
import SubscriptionButton from "../../../SubscriptionButton";

import BulletPoint from "./Bulletpoint";

interface Props {
  billing?: boolean;
}

const UpsellContent: FC<Props> = ({ billing = false }) => {
  const theme = useTheme();
  const { t } = useTranslation(["permissions"]);
  const { organisations, selectedOrganisation, refetchOrganisations } = useDashboardContext();
  const organisation = organisations.find(o => o.id === selectedOrganisation);

  const payingOrganisation = organisations.find(o => o.id === organisation?.subscriptionHolder.organisationId);
  const isAdminOnPayingOrg =
    payingOrganisation === undefined
      ? false
      : payingOrganisation.permissions.includes("organisation.organisation_data:read");

  const { createCustomerPortalSession } = useStripe({ organisationId: selectedOrganisation });

  if (organisation === undefined) return null;

  return (
    <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
      <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
        <Text variant="xLargePlus">
          {billing ? t("upsell.titleBilling", { ns: "permissions" }) : t("upsell.title", { ns: "permissions" })}
        </Text>
        <Text>{t("upsell.explainer", { ns: "permissions" })}</Text>
      </Stack>
      <Stack
        horizontal
        tokens={{ childrenGap: theme.spacing.l2, padding: theme.spacing.l2 }}
        styles={{ root: { width: "100%" } }}
        horizontalAlign="space-evenly"
      >
        <Stack tokens={{ childrenGap: theme.spacing.l2 }}>
          <Stack horizontalAlign="center">
            <Image src={"/images/monitr-lite.svg"} height={35} />
          </Stack>
          <Stack tokens={{ childrenGap: theme.spacing.s1 }} styles={{ root: { height: 175 } }}>
            {/* We can't import monitr blue here because the theme throws an error, to investigate later */}
            <BulletPoint color={"#1486FC"} text={t("upsell.lite.reports", { ns: "permissions" })} />
            <BulletPoint color={"#1486FC"} text={t("upsell.lite.dashboard", { ns: "permissions" })} />
            <BulletPoint color={"#1486FC"} text={t("upsell.lite.outstandingItems", { ns: "permissions" })} />
            <BulletPoint color={"#1486FC"} text={t("upsell.lite.bookkeeping", { ns: "permissions" })} />
          </Stack>
          {organisation.subscriptionHolder.paymentState === "paused" && (
            <Stack horizontalAlign="center">
              <SubscriptionButton
                text={t("subscriptionButton.start", { ns: "billing", plan: "Lite" })}
                iconName={"FavoriteStar"}
                tier={organisation.tier}
                onClick={async () => {
                  const [sessionUrl] = await Promise.all([
                    axios.patch(`/organisations/${organisation?.id}/monitr-subscription`, {
                      tier: SubscriptionTier.Lite,
                    }),
                    createCustomerPortalSession(),
                  ]);
                  window.open(sessionUrl.data, "_blank");
                  await refetchOrganisations();
                }}
              />
            </Stack>
          )}
        </Stack>
        <Stack tokens={{ childrenGap: theme.spacing.l2 }}>
          <Stack horizontalAlign="center">
            <Image src={"/images/monitr-premium.svg"} height={35} />
          </Stack>
          <Stack tokens={{ childrenGap: theme.spacing.s1 }} styles={{ root: { height: 175 } }}>
            {/* We can't import monitr yellow here because the theme throws an error, to investigate later */}
            <BulletPoint color={"#EEC762"} text={t("upsell.premium.lite", { ns: "permissions" })} />
            <BulletPoint color={"#EEC762"} text={t("upsell.premium.forecasting", { ns: "permissions" })} />
            <BulletPoint color={"#EEC762"} text={t("upsell.premium.reports", { ns: "permissions" })} />
            <BulletPoint color={"#EEC762"} text={t("upsell.premium.dashboards", { ns: "permissions" })} />
            <BulletPoint color={"#EEC762"} text={t("upsell.premium.consolidation", { ns: "permissions" })} />
            <BulletPoint color={"#EEC762"} text={t("upsell.premium.integrations", { ns: "permissions" })} />
          </Stack>
          {organisation.subscriptionHolder.paymentState === "paused" && (
            <Stack horizontalAlign="center">
              <SubscriptionButton
                text={t("subscriptionButton.start", { ns: "billing", plan: "Premium" })}
                iconName={"Diamond"}
                tier={organisation.tier}
                onClick={async () => {
                  const [sessionUrl] = await Promise.all([
                    axios.patch(`/organisations/${organisation?.id}/monitr-subscription`, {
                      tier: SubscriptionTier.Premium,
                    }),
                    createCustomerPortalSession(),
                  ]);
                  window.open(sessionUrl.data, "_blank");
                  await refetchOrganisations();
                }}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack.Item styles={{ root: { width: 250 } }}>
        {isAdminOnPayingOrg && organisation.subscriptionHolder.paymentState !== "paused" && (
          <SubscriptionButton
            text={
              organisation.subscriptionHolder.paymentState === "trialing" && organisation.tier === SubscriptionTier.Lite
                ? t("paymentStatus.try", { ns: "billing" })
                : organisation.tier === SubscriptionTier.Premium
                  ? t("paymentStatus.downgrade", { ns: "billing" })
                  : t("paymentStatus.upgrade", { ns: "billing" })
            }
            iconName={organisation.tier === SubscriptionTier.Lite ? "Diamond" : "Down"}
            tier={organisation.tier}
            onClick={async () => {
              await axios.patch(`/organisations/${organisation?.id}/monitr-subscription`, {
                tier: organisation.tier === SubscriptionTier.Lite ? SubscriptionTier.Premium : SubscriptionTier.Lite,
              });
              await refetchOrganisations();
            }}
            ignoreAreYouSure={organisation.subscriptionHolder.paymentState === "trialing"}
          />
        )}
      </Stack.Item>
    </Stack>
  );
};

export default UpsellContent;
