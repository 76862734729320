import { IButtonProps, PrimaryButton, useTheme } from "@fluentui/react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { SubscriptionTier } from "../../../types/organisation";
import AreYouSure from "../../atoms/AreYouSure";

interface Props extends IButtonProps {
  text: string;
  iconName: string;
  tier: SubscriptionTier;
  onClick: () => void;
  ignoreAreYouSure?: boolean;
}

const SubscriptionButton: FC<Props> = ({ text, iconName, tier, onClick, ignoreAreYouSure = false, styles }) => {
  const { t } = useTranslation(["billing"]);
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [openAreYouSureChangeSub, setOpenAreYouSureChangeSub] = useState<boolean>(false);

  return (
    <>
      <PrimaryButton
        text={text}
        iconProps={{ iconName }}
        styles={{
          root: {
            paddingLeft: theme.spacing.s1,
            paddingRight: theme.spacing.s1,
          },
          ...styles,
        }}
        onClick={async () => {
          if (ignoreAreYouSure) {
            setLoading(true);
            await onClick();
            setLoading(false);
          } else setOpenAreYouSureChangeSub(true);
        }}
        disabled={loading}
      />
      {openAreYouSureChangeSub && (
        <AreYouSure
          title={t("paymentStatus.changeSubscription.title", { ns: "billing" })}
          explainer={
            tier === SubscriptionTier.Premium
              ? t("paymentStatus.changeSubscription.explainerToLite", {
                  ns: "billing",
                })
              : t("paymentStatus.changeSubscription.explainerToPremium", {
                  ns: "billing",
                })
          }
          onApproval={async () => {
            await onClick();
            setOpenAreYouSureChangeSub(false);
          }}
          onDismiss={() => setOpenAreYouSureChangeSub(false)}
          isDangerous
          customWidth={400}
        />
      )}
    </>
  );
};

export default SubscriptionButton;
