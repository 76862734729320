import { registerIcons } from "@fluentui/react";

import { ReactComponent as LogoIconLight } from "./logo-icon-light.svg";
import { ReactComponent as LogoIconNeon } from "./logo-icon-neon.svg";

// Register custom icons
registerIcons({
  icons: {
    logoIconLight: <LogoIconLight />,
    logoIconNeon: <LogoIconNeon />,
  },
});
