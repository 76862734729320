import { FC, useEffect } from "react";

import { useJourny } from "../context/JournyContext";

interface Props {
  screenName: string;
}

const JournyScreenView: FC<Props> = ({ screenName, children }) => {
  const { logScreen } = useJourny();

  useEffect(() => {
    logScreen(screenName);
  }, [logScreen, screenName]);

  return <>{children}</>;
};

export default JournyScreenView;
