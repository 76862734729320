import i18n from "i18next";
import lookupClosestLocale from "lookup-closest-locale";
import numbro from "numbro";
import { FC, createContext, useContext, useEffect, useState } from "react";

import { defaultLocale, supportedNumbroLocales } from "../i18n";

export interface ContextInterface {
  updateLanguage: (lng: string) => void;
  updateDelimiters: (delimiters: Exclude<ContextInterface["delimiters"], undefined>) => void;
  delimiters?: { thousands: " "; decimal: "," } | { thousands: ","; decimal: "." };
}

const UserSettingsContext = createContext<ContextInterface>({
  updateLanguage: () => {},
  updateDelimiters: () => {},
});

export const UserSettingsProvider: FC = ({ children }) => {
  const initialDelimiters = {
    thousands: window.localStorage.getItem("thousandsSeparator"),
    decimal: window.localStorage.getItem("decimalSeparator"),
  } as ContextInterface["delimiters"];
  const [delimiters, setDelimiters] = useState<ContextInterface["delimiters"]>(initialDelimiters);

  // Validates if there's correct data on the local storage for the delimiters if not set the language delimiters
  useEffect(() => {
    if (
      !validateDelimiters(
        window.localStorage.getItem("thousandsSeparator"),
        window.localStorage.getItem("decimalSeparator"),
      )
    ) {
      updateDelimitersBasedOnLanguage(window.localStorage.getItem("language") ?? "en-GB");
    }
  }, []);

  function validateDelimiters(thousands: string | null, decimal: string | null) {
    if (!thousands || !decimal) return;
    return (thousands === " " && decimal === ",") || (thousands === "," && decimal === ".");
  }

  async function updateLanguage(lng: string) {
    i18n.changeLanguage(lng);
    await updateDelimitersBasedOnLanguage(lng);
  }

  async function updateDelimitersBasedOnLanguage(lng: string) {
    const closestSupportedNumbroLocale = lookupClosestLocale(lng, supportedNumbroLocales) || defaultLocale;
    const numbroLocale = await import(`numbro/languages/${closestSupportedNumbroLocale}`);
    const { thousands, decimal } = numbroLocale.default.delimiters;
    setDelimiters({ thousands, decimal });
    window.localStorage.setItem("decimalSeparator", decimal);
    window.localStorage.setItem("thousandsSeparator", thousands);
  }

  async function updateDelimiters(delimiters: Exclude<ContextInterface["delimiters"], undefined>) {
    const lng = localStorage.getItem("language") ?? "en-GB";
    const closestSupportedNumbroLocale = lookupClosestLocale(lng, supportedNumbroLocales) || defaultLocale;
    const numbroLocale = await import(`numbro/languages/${closestSupportedNumbroLocale}`);
    numbro.registerLanguage(
      {
        ...numbroLocale.default,
        delimiters,
      },
      true,
    );
    setDelimiters(delimiters);
    window.localStorage.setItem("decimalSeparator", delimiters.decimal);
    window.localStorage.setItem("thousandsSeparator", delimiters.thousands);
  }

  const context: ContextInterface = {
    updateLanguage,
    updateDelimiters,
    delimiters,
  };

  return <UserSettingsContext.Provider value={context}>{children}</UserSettingsContext.Provider>;
};

export function useUserSettings(): ContextInterface {
  return useContext(UserSettingsContext);
}
