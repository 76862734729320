import { ThemeProvider } from "@fluentui/react";
import { StrictMode } from "react";
import { render } from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";

// Reset CSS
import "./index.css";

import "./i18n";
import App from "./App";
import { AuthenticationProvider } from "./context/AuthenticationContext";
import { monitrLightComponents } from "./theme/components/light";
import { monitrLightTheme } from "./theme/theme";

const components = monitrLightComponents;
export const theme = monitrLightTheme;

render(
  <StrictMode>
    <HelmetProvider>
      <Router>
        <AuthenticationProvider>
          {/* TODO: Follow up on issue to remove intermediate element (currently span)  */}
          {/* https://github.com/microsoft/fluentui/issues/16633 */}
          <ThemeProvider theme={{ ...theme, components }} applyTo="body" as="span">
            <App />
          </ThemeProvider>
        </AuthenticationProvider>
      </Router>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root"),
);
