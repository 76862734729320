import { DefaultButton, MessageBar, MessageBarType, Stack, useTheme } from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useDashboardContext } from "../../../../../context/DashboardContext";
import JournyScreenView from "../../../../../journy";
import { JournyScreens } from "../../../../../journy/types";
import { SubscriptionTier } from "../../../../../types/organisation";
import useStripe from "../../../../hooks/useStripe";
import UpsellContent from "../../../../molecules/Protected/UpsellScreen/UpsellContent";

import PaymentExchange from "./PaymentExchange";
import PaymentStatus from "./PaymentStatus";
import SubscriptionBreakdown from "./SubscriptionBreakdown";

const Billing: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(["billing"]);
  const { selectedOrganisation, organisations } = useDashboardContext();
  const organisation = organisations.find(o => o.id === selectedOrganisation);
  const { createCustomerPortalSession } = useStripe({ organisationId: selectedOrganisation });

  if (organisation === undefined) return null;

  const payingForYourself = organisation.id === organisation.subscriptionHolder.organisationId;
  const paymentIsOk = ["trialing", "active"].includes(organisation.subscriptionHolder.paymentState);

  return (
    <JournyScreenView screenName={JournyScreens.Billing}>
      <Stack styles={{ root: { height: "100%" } }}>
        {!paymentIsOk && (
          <MessageBar
            messageBarType={MessageBarType.blocked}
            styles={{ root: { paddingLeft: 10 } }}
            isMultiline={false}
            actions={
              <DefaultButton
                iconProps={{ iconName: "PaymentCard" }}
                text={t("paymentStatus.cta", { ns: "billing" })}
                onClick={async () => {
                  const sessionUrl = await createCustomerPortalSession();
                  window.open(sessionUrl.data, "_blank");
                }}
                styles={{ root: { height: 25 } }}
              />
            }
          >
            {t("paymentStatus.messageBarBlocked", { ns: "billing" })}
          </MessageBar>
        )}
        <Stack
          horizontal
          tokens={{ padding: theme.spacing.l1, childrenGap: theme.spacing.m }}
          styles={{ root: { height: "100%" } }}
        >
          <Stack
            tokens={{ childrenGap: theme.spacing.m }}
            styles={{ root: { width: 500, minWidth: 500, maxWidth: 500 } }}
          >
            <PaymentStatus payingForYourself={payingForYourself} />
            <Stack.Item grow verticalFill>
              {payingForYourself && <PaymentExchange />}
            </Stack.Item>
          </Stack>
          <Stack.Item grow verticalFill>
            {organisation?.tier === SubscriptionTier.Premium ? (
              <SubscriptionBreakdown paymentIsOk={paymentIsOk} />
            ) : (
              <Card
                tokens={{ padding: theme.spacing.l1, childrenGap: theme.spacing.m }}
                styles={{ root: { maxWidth: "100%", width: "100%" } }}
              >
                <UpsellContent billing />
              </Card>
            )}
          </Stack.Item>
        </Stack>
      </Stack>
    </JournyScreenView>
  );
};

export default Billing;
