import { Customizations, DayOfWeek, FirstWeekOfYear, IDatePickerProps } from "@fluentui/react";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi, { HttpBackendOptions } from "i18next-http-backend";
import lookupClosestLocale from "lookup-closest-locale";
import moment from "moment";
import numbro from "numbro";
import { initReactI18next } from "react-i18next";

// Try to load the translation mapping file
// Won't exist on a dev environment
let translationMapping = {};
if (process.env.NODE_ENV === "production") translationMapping = require("./mappings.json");

const defaultLang = "en";
export const defaultLocale = "en-GB";
export const supportedLocales = [defaultLocale, "nl-BE"];
const supportedLanguages = [defaultLang, "nl"];
// Supported moment.js locales (NOTE: they are in lower-case)
const supportedMomentLocales = {
  [defaultLocale.toLocaleLowerCase()]: 1,
  "nl-be": 1,
};
export const supportedNumbroLocales = supportedLocales.reduce<Record<string, number>>((prev, cur) => {
  prev[cur] = 1;
  return prev;
}, {});

const backendOptions: HttpBackendOptions = {
  // Load the actual file path from the mapping file
  loadPath: (ns, lng) => {
    const key = `${ns}/${lng}.json`;
    const hashFile = (translationMapping as Record<string, string>)[key] || key;
    return `/locales/${hashFile}`;
  },
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // lng: 'nl-BE',
    ns: [], // Do not load any namespaces by default
    preload: false,
    debug: process.env.NODE_ENV === "development",
    supportedLngs: supportedLanguages,
    // fallbackLng: defaultLang,
    fallbackLng: false,
    fallbackNS: false,
    defaultNS: "common",
    keySeparator: ".",
    nsSeparator: ":",
    load: "languageOnly",
    nonExplicitSupportedLngs: true,
    cleanCode: true,
    interpolation: {
      escapeValue: false, // react already saves us from xss
      skipOnVariables: false,
      // format: function (value, format, lng) {
      //   if (format === 'capitalize') return value.toUpperCase();
      //   return value;
      // },
    },
    backend: backendOptions,
    detection: {
      order: ["querystring", "localStorage", "navigator"],
      lookupQuerystring: "lng",
      lookupLocalStorage: "language",
      caches: ["localStorage"],
    },
    react: {
      useSuspense: true,
    },
  });

// Migration script for old language store
i18n.on("initialized", () => {
  const storedLng = localStorage.getItem("language");
  if (!storedLng) return;

  // If the language we stored in localstorage is not found in the list of supported locales,
  // we force a change to the default language
  if (!supportedLocales.includes(storedLng)) i18n.changeLanguage(defaultLocale);
});

i18n.on("languageChanged", async lng => {
  const closestSupportedNumbroLocale = lookupClosestLocale(lng, supportedNumbroLocales) || defaultLocale;
  const closestSupportedMomentLocale =
    lookupClosestLocale(lng.toLocaleLowerCase(), supportedMomentLocales) || defaultLocale;
  await import(
    /* webpackChunkName: "moment-locale" */ `moment/locale/${closestSupportedMomentLocale.toLocaleLowerCase()}`
  );
  moment.locale(closestSupportedMomentLocale);
  // Register numbro language
  if (lng !== "en-US") {
    const numbroLocale = await import(
      /* webpackChunkName: "numbro-locale" */ `numbro/languages/${closestSupportedNumbroLocale}`
    );
    numbro.registerLanguage(
      {
        ...numbroLocale.default,
        // Override abbreviations, removes the abbreviation
        delimiters: {
          thousands: localStorage.getItem("thousandsSeparator") ?? numbroLocale.default.delimiters.thousands,
          decimal: localStorage.getItem("decimalSeparator") ?? numbroLocale.default.delimiters.decimal,
        },
        abbreviations: {
          thousand: "",
          million: "",
          billion: "",
          trillion: "",
        },
      },
      true,
    );
  }

  const localeData = moment.localeData();
  const firstDayOfWeek = localeData.firstDayOfWeek();
  // const firstWeekOfYear = localeData.firstDayOfYear();
  const weekDays = localeData.weekdays();
  const weekDaysShort = localeData.weekdaysMin();
  const monthsShort = localeData.monthsShort();
  const months = localeData.months();

  // We need to wait for the namespace to load before we can translate
  i18n.loadNamespaces("datepicker", () => {
    // Apply scoped component changes
    Customizations.applyScopedSettings("DatePicker", {
      firstDayOfWeek: firstDayOfWeek as DayOfWeek,
      firstWeekOfYear: FirstWeekOfYear.FirstFourDayWeek, // ISO 8601, Gregorian calendar. TODO: Set this based on locale
      strings: {
        months,
        shortMonths: monthsShort,
        days: weekDays,
        shortDays: weekDaysShort,
        goToToday: i18n.t("go_to_today", { ns: "datepicker" }),
      },
    } as Partial<IDatePickerProps>);
  });
});
// eslint-disable-next-line import/no-unused-modules
export default i18n;
