import { IDetailsListProps, IDialogFooterStyles, Theme } from "@fluentui/react";

const initComponents = (theme: Theme) => {
  const components = {
    DetailsList: {
      styles: {
        root: {
          selectors: {
            [`&.with-inputs .ms-List-cell`]: {
              minHeight: 44,
            },
          },
        },
      },
    } as Partial<IDetailsListProps>,
    Card: {
      styles: {
        root: {
          position: "relative",
          "&[data-disabled=true]::before": {
            content: `""`,
            zIndex: 1,
            display: "block",
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: theme.palette.blackTranslucent40,
          },
        },
      },
    },
    DialogFooter: {
      styles: {
        action: { height: 32 },
        actionsRight: {
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "end",
          alignItems: "center",
        },
      } as IDialogFooterStyles,
    },
  };

  return components;
};

export default initComponents;
