import { ITooltipHostProps, TooltipHost } from "@fluentui/react";
import { FC } from "react";

type Props = ITooltipHostProps & { disabled: boolean };

const DisableableTooltip: FC<Props> = ({ disabled, children, ...rest }) => {
  return disabled ? <>{children}</> : <TooltipHost {...rest}>{children}</TooltipHost>;
};

export default DisableableTooltip;
