import { useState } from "react";
import { useTranslation } from "react-i18next";

import EmptyState from "../components/molecules/EmptyState";
import { minWindowWidth } from "../util/utils";

const ScreenSizeRoute = ({ children }: { children: JSX.Element }) => {
  const { t } = useTranslation(["common"]);
  const [smallScreen, setSmallScreen] = useState<boolean>(window.innerWidth < minWindowWidth);
  window.addEventListener("resize", () => setSmallScreen(window.innerWidth < minWindowWidth));

  return smallScreen ? (
    <EmptyState
      title={t("errors.small_screen_not_optimized", { ns: "common" })}
      explainer={t("help.let_us_know", { ns: "common" })}
      iconName="CellPhone"
    />
  ) : (
    children
  );
};

export default ScreenSizeRoute;
