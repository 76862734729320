import {
  CommandBar,
  DefaultButton,
  ICommandBarItemProps,
  IStackProps,
  Persona,
  PersonaSize,
  PrimaryButton,
  Stack,
  Text,
  TextField,
  useTheme,
} from "@fluentui/react";
import moment from "moment";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Annotation as IAnnotation } from "../../../types/annotation";

import styles from "./index.module.scss";

interface Props {
  annotation: IAnnotation;
  prefix?: string;
  stackProps?: IStackProps;
  onEditAnnotation?: (annotationId: string, content: string) => Promise<void>;
  onDeleteAnnotation?: (annotationId: string) => Promise<void>;
  disabled?: boolean;
}

const Annotation: FC<Props> = ({ prefix, annotation, onEditAnnotation, onDeleteAnnotation, disabled = false }) => {
  const theme = useTheme();
  const { t } = useTranslation(["annotations"]);
  const [editMode, setEditMode] = useState(false);
  const [editedContent, setEditedContent] = useState(annotation.content);

  const commandBarItems: ICommandBarItemProps[] = [];

  if (onEditAnnotation)
    commandBarItems.push({
      key: "edit",
      text: t("actions.edit", { ns: "common" }),
      iconProps: { iconName: "Edit" },
      onClick: () => setEditMode(true),
      disabled,
    });

  if (onDeleteAnnotation)
    commandBarItems.push({
      key: "delete",
      text: t("actions.delete", { ns: "common" }),
      iconProps: {
        iconName: "Delete",
        styles: {
          root: { color: theme.palette.red },
        },
      },
      onClick: () => {
        onDeleteAnnotation(annotation.id);
      },
      disabled,
    });

  return (
    <Stack
      tokens={{ childrenGap: theme.spacing.s1 }}
      styles={{ root: { position: "relative" } }}
      horizontalAlign="space-between"
    >
      <Stack horizontal>
        <Stack.Item grow styles={{ root: { overflow: "hidden" } }}>
          <Persona
            text={
              annotation.createdBy !== null
                ? `${annotation.createdBy.firstName} ${annotation.createdBy.lastName}`
                : t("unknown_user", { ns: "annotations" })
            }
            secondaryText={
              annotation.createdAt === annotation.updatedAt
                ? t("created", {
                    ns: "annotations",
                    time: moment(annotation.createdAt).fromNow(),
                  })
                : t("last_edited", {
                    ns: "annotations",
                    time: moment(annotation.updatedAt).fromNow(),
                    name:
                      annotation.lastEditedBy !== null
                        ? `${annotation.lastEditedBy.firstName} ${annotation.lastEditedBy.lastName}`
                        : t("unknown_user", { ns: "annotations" }),
                  })
            }
            size={PersonaSize.size40}
            showUnknownPersonaCoin={annotation.createdBy === null}
          />
        </Stack.Item>
        {!disabled && <CommandBar styles={{ root: { padding: 0 } }} items={[]} overflowItems={commandBarItems} />}
      </Stack>
      {editMode ? (
        <>
          <TextField
            multiline
            resizable={false}
            placeholder={t("add.placeholder", { ns: "annotations" })}
            value={editedContent}
            onChange={(_, newValue) => {
              if (newValue === undefined) return;
              setEditedContent(newValue);
            }}
          />
          <Stack horizontal tokens={{ childrenGap: theme.spacing.m }} horizontalAlign="end">
            <DefaultButton
              onClick={() => {
                setEditedContent(annotation.content);
                setEditMode(false);
              }}
            >
              {t("actions.cancel", { ns: "common" })}
            </DefaultButton>
            <PrimaryButton
              onClick={() => {
                setEditMode(false);
                onEditAnnotation && onEditAnnotation(annotation.id, editedContent);
              }}
              disabled={editedContent.length === 0}
            >
              {t("actions.save", { ns: "common" })}
            </PrimaryButton>
          </Stack>
        </>
      ) : (
        <Text as="p" className={styles.annotationContent} variant="smallPlus">
          {prefix && <strong style={{ fontWeight: 700 }}>{prefix}: </strong>}
          {annotation.content}
        </Text>
      )}
    </Stack>
  );
};

export default Annotation;
