import { Stack, Text, useTheme } from "@fluentui/react";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from "@fluentui/react-accordion";
import { Card } from "@fluentui/react-cards";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useEntityContext } from "../../../../../../context/EntityContext";
import EmptyState from "../../../../../molecules/EmptyState";

import EntityRevenueGraph from "./EntityRevenueGraph";

interface Props {
  paymentIsOk: boolean;
}

const SubscriptionBreakdown: FC<Props> = ({ paymentIsOk }) => {
  const theme = useTheme();
  const { t } = useTranslation(["billing"]);
  const { entities } = useEntityContext();

  return (
    <Card
      tokens={{ padding: theme.spacing.l1, childrenGap: theme.spacing.m }}
      styles={{ root: { maxWidth: "100%", width: "100%" } }}
    >
      <Card.Item>
        <Text variant="xLarge">{t("subscriptionBreakdown.title", { ns: "billing" })}</Text>
      </Card.Item>
      <Card.Item styles={{ root: { display: "flex", flex: 1, minHeight: 0 } }}>
        <Stack styles={{ root: { flex: 1, minHeight: 0, overflowY: "auto" } }}>
          {!paymentIsOk ? (
            <EmptyState
              title={t("subscriptionBreakdown.emptyStates.blocked.title", { ns: "billing" })}
              explainer={t("subscriptionBreakdown.emptyStates.blocked.explainer", { ns: "billing" })}
            />
          ) : entities.length === 0 ? (
            <EmptyState
              title={t("subscriptionBreakdown.emptyStates.noEntities.title", { ns: "billing" })}
              explainer={t("subscriptionBreakdown.emptyStates.noEntities.explainer", { ns: "billing" })}
            />
          ) : (
            <Accordion collapsible defaultOpenItems={entities[0].id}>
              {entities.map(e => (
                <AccordionItem key={e.id} value={e.id}>
                  <AccordionHeader>{e.name}</AccordionHeader>
                  <AccordionPanel>
                    <EntityRevenueGraph entity={e} />
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </Stack>
      </Card.Item>
    </Card>
  );
};

export default SubscriptionBreakdown;
