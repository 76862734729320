import { Spinner, SpinnerSize } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import EmptyState from "../components/molecules/EmptyState";
import { useDashboardContext } from "../context/DashboardContext";
import { useEntityContext } from "../context/EntityContext";

interface Props {
  children: JSX.Element;
}

/**
 * This route will enfore the existence of an entity before rendering any content
 */
const EntityRoute = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["teachings", "permissions"]);
  const { organisations, selectedOrganisation } = useDashboardContext();
  const { initialLoading, entities } = useEntityContext();

  // Loading entities from the context for the first time
  if (initialLoading) return <Spinner style={{ height: "100%" }} size={SpinnerSize.large} />;

  // If there are no active entities and the user is not an organisation administrator
  if (
    entities.length === 0 &&
    !organisations
      .find(o => o.id === selectedOrganisation)
      ?.permissions.includes("organisation.organisation_data:write")
  )
    return (
      <EmptyState
        title={t("no_entities.title", { ns: "permissions" })}
        explainer={t("no_entities.explainer", { ns: "permissions" })}
      />
    );

  // No entities
  if (entities.length === 0) navigate("/onboarding");

  return props.children;
};

export default EntityRoute;
