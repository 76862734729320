import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";

import EmptyState from "../components/molecules/EmptyState";
import { useEntityContext } from "../context/EntityContext";

export const DashboardIndexRoute: FC = () => {
  const { t } = useTranslation(["permissions"]);
  const { selectedEntity, entities } = useEntityContext();

  if (entities.find(e => e.id === selectedEntity)?.permissions.includes("entity.reports:read"))
    return <Navigate to="./metrics" />;
  if (entities.find(e => e.id === selectedEntity)?.permissions.includes("entity.core:read"))
    return <Navigate to="./revenue/recurring-revenue" />;
  if (entities.find(e => e.id === selectedEntity)?.permissions.includes("entity.receivables:read"))
    return <Navigate to="./outstanding-items/receivables" />;
  if (entities.find(e => e.id === selectedEntity)?.permissions.includes("entity.payables:read"))
    return <Navigate to="./outstanding-items/payables" />;
  return (
    <EmptyState
      title={t("unauthorized.page.title", { ns: "permissions" })}
      explainer={t("unauthorized.page.explainer", { ns: "permissions" })}
      iconName="HandsFree"
    />
  );
};
