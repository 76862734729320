import useAxios from "axios-hooks";
import { useCallback } from "react";

import { BudgetType, BudgetVersion } from "./types";

interface Params {
  entityId?: string;
  budgetType?: BudgetType | BudgetType[];
  manual?: boolean;
}

export const useBudgetVersions = ({ entityId, budgetType, manual }: Params) => {
  const [{ data: budgetVersions, loading, error }, refetchBudgetVersions] = useAxios<BudgetVersion[]>(
    {
      url: `/entities/${entityId}/budget-versions`,
      params: { type: budgetType },
    },
    {
      manual: entityId === undefined || manual,
    },
  );

  const refetch = useCallback(async () => {
    await refetchBudgetVersions();
  }, [refetchBudgetVersions]);

  if (error !== null) throw error;

  return {
    budgetVersions,
    loading,
    refetch,
  };
};
