import { ActionButton, ITextProps, MessageBar, Separator, Spinner, Stack, Text, useTheme } from "@fluentui/react";
import { TickRendererProps } from "@visx/axis";
import { Line } from "@visx/shape";
import { NumberValue } from "d3-scale";
import moment from "moment";
import numbro from "numbro";
import { FC, ReactNode, Suspense } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Annotation as IAnnotation } from "../../../../types/annotation";
import { assertUnreachable } from "../../../../util/utils";
import { PeriodAggregation } from "../../../hooks/useReports/types";
import Annotation from "../../../molecules/Annotation";
import styles from "../index.module.scss";

export const cardinalityToAbbreviation = {
  1: "",
  1000: "K",
  1000000: "M",
  1000000000: "B",
};

export const tooltipNumbroOptions = {
  mantissa: 0,
  optionalMantissa: false,
  trimMantissa: false,
  thousandSeparated: true,
};

export const strokeWidth = 2;
export const dateTickWidth = 40;

export type Shape = "square" | "circle";
export interface TooltipPoint {
  id: string;
  title: string;
  color: string;
  shape?: Shape;
  value: number;
  suffix?: string;
  prefix?: string;
  formatValue?: (value: number) => string;
  date?: Date | string;
  previousPeriod?: boolean;
  squareComponent?: JSX.Element;
}

export interface GraphPointResponse {
  id: string;
  title: string;
  rank: number;
  data: CoordinateResponse[];
  prevData?: CoordinateResponse[];
}

export interface GraphPoint extends Omit<GraphPointResponse, "data" | "prevData"> {
  id: string;
  title: string;
  data: Coordinate[];
  prevData?: Coordinate[];
}

export interface CoordinateResponse {
  value: string;
  originalDate: string;
  dateToMatch: string;
}

export interface Coordinate extends Omit<CoordinateResponse, "value"> {
  value: number;
}

interface TooltipContentProps {
  title: string;
  points: TooltipPoint[];
  annotations?: IAnnotation[];
  maxAnnotationsToShow?: number;
  pinned?: boolean;
  onUnpin?: () => void;
  customTooltipNumbroOptions?: numbro.Format;
}

const graphTitleProps: ITextProps = {
  styles: {
    root: {
      fontWeight: 600,
      lineHeight: "1.3",
      textAlign: "center",
      userSelect: "none",
    },
  },
};

export const GraphTitle: FC = ({ children }) => {
  return (
    <Text as="h1" block {...graphTitleProps}>
      {children}
    </Text>
  );
};

export const defaultAxisTickLabelProps = {
  fill: "#294360",
  fontSize: 11,
  lineHeight: 14,
  dy: 14 / 4, // lineHeight / 4
};

export const formatTooltipDateTitle = (date: string, aggregation?: PeriodAggregation): string => {
  if (aggregation === undefined) return moment(date).format("MMMM YYYY");
  return moment(date).format(
    aggregation.value === "year" ? "YYYY" : aggregation.value === "quarter" ? "[Q]Q YYYY" : "MMMM YYYY",
  );
};

// Custom ticks
export const dateTick = (
  tickRendererProps: TickRendererProps,
  isCrossYear: boolean,
  aggregation?: PeriodAggregation,
): ReactNode => {
  const dateString = tickRendererProps.formattedValue;
  delete tickRendererProps.formattedValue;
  delete tickRendererProps.lineHeight; // Line height prop does not exist on plain SVG text elements
  let formattedDate = moment(dateString).format("MMM");
  if (aggregation !== undefined) {
    switch (aggregation.value) {
      case "month":
        formattedDate = moment(dateString).format("MMM");
        break;
      case "quarter":
        formattedDate = moment(dateString).format("[Q]Q");
        break;
      case "year":
        formattedDate = moment(dateString).format("YYYY");
        break;
      default:
        assertUnreachable(aggregation.value);
    }
  }

  return (
    <text {...tickRendererProps}>
      <tspan x={tickRendererProps.x}>{formattedDate}</tspan>
      {isCrossYear && (
        <tspan x={tickRendererProps.x} dy="16">
          {moment(dateString).format("YY")}
        </tspan>
      )}
    </text>
  );
};

export const categoryTick = (tickRendererProps: TickRendererProps): ReactNode => {
  const categoryString = tickRendererProps.formattedValue;
  delete tickRendererProps.formattedValue;
  delete tickRendererProps.lineHeight; // Line height prop does not exist on plain SVG text elements

  return (
    <text {...tickRendererProps}>
      <tspan x={tickRendererProps.x}>{categoryString}</tspan>
    </text>
  );
};

export const formatValue = (val: NumberValue, cardinality: number, axisWithMantissa?: boolean) =>
  numbro(val)
    .divide(cardinality)
    .format({ ...tooltipNumbroOptions, mantissa: axisWithMantissa ? 1 : 0 });

export const ColoredTooltipSquare: FC<{ color: string }> = ({ color }) => {
  return (
    <div
      style={{
        width: "10px",
        height: "10px",
        background: color,
        display: "inline-block",
      }}
    />
  );
};

export const ColoredTooltipLine: FC<{ color: string }> = ({ color }) => {
  return (
    <div>
      <div
        style={{
          width: 10,
          height: 4,
          background: "transparent",
        }}
      />
      <div
        style={{
          width: 10,
          height: 2,
          background: color,
        }}
      />
      <div
        style={{
          width: 10,
          height: 4,
          background: "transparent",
        }}
      />
    </div>
  );
};

export const ColoredTooltipCircle: FC<{ color: string }> = ({ color }) => {
  return (
    <div
      style={{
        width: "10px",
        height: "10px",
        background: color,
        display: "inline-block",
        borderRadius: "50%",
      }}
    />
  );
};

function getIconByShape(shape: Shape, color: string) {
  switch (shape) {
    case "square":
      return <ColoredTooltipSquare color={color} />;
    case "circle":
      return <ColoredTooltipCircle color={color} />;
    default:
      return null;
  }
}

export const TooltipContent: FC<TooltipContentProps> = ({
  title,
  points,
  annotations,
  maxAnnotationsToShow = Infinity,
  pinned = false,
  onUnpin,
  customTooltipNumbroOptions,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(); // Loading here will introduce flashing content due to Suspense

  return (
    <Suspense fallback={<Spinner />}>
      <Stack horizontal tokens={{ childrenGap: theme.spacing.m, padding: 12 }}>
        <Stack tokens={{ childrenGap: theme.spacing.m }}>
          <Text block nowrap className={styles.title} styles={{ root: { fontWeight: 600 } }}>
            {title}
          </Text>
          <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
            <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
              {points.map(p => {
                return (
                  <Stack
                    key={`stack_${p.id}`}
                    horizontal
                    verticalAlign="baseline"
                    tokens={{ childrenGap: theme.spacing.s2 }}
                  >
                    {p.squareComponent ?? (p.shape && getIconByShape(p.shape, p.color))}
                    <Text block nowrap>
                      {p.title}
                    </Text>
                  </Stack>
                );
              })}
            </Stack>
            <Stack tokens={{ childrenGap: theme.spacing.s2 }} horizontalAlign="end">
              {points.map(p => {
                return (
                  <Text key={`text_${p.id}`} block nowrap className={styles.value}>
                    {["string", "number"].includes(typeof p.value)
                      ? `${p.prefix || ""}${
                          p.formatValue !== undefined
                            ? p.formatValue(p.value)
                            : numbro(p.value).format(customTooltipNumbroOptions ?? tooltipNumbroOptions)
                        }${p.suffix || ""}`
                      : "N/A"}
                  </Text>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
        {annotations && annotations.length > 0 && (
          <Stack tokens={{ childrenGap: theme.spacing.m }}>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
              <Separator vertical />
              <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
                <Suspense fallback={<Spinner />}>
                  <Stack
                    styles={{ root: { overflow: "auto" } }}
                    tokens={{ maxWidth: 300, childrenGap: theme.spacing.m, maxHeight: 300 }}
                  >
                    {annotations.slice(0, maxAnnotationsToShow).map(annotation => {
                      return (
                        <Annotation
                          key={annotation.id}
                          prefix={annotation.reportingLine?.name}
                          annotation={annotation}
                        />
                      );
                    })}
                  </Stack>
                  {annotations.length > maxAnnotationsToShow && (
                    <Text block variant="smallPlus" styles={{ root: { color: theme.semanticColors.bodySubtext } }}>
                      <Trans
                        i18nKey="tooltip.has_more_annotations"
                        ns="annotations"
                        count={annotations.length - maxAnnotationsToShow}
                        defaults="To see <strong>{{count}} more annotation</strong>, click the graph."
                        components={{
                          strong: <strong style={{ fontWeight: 700 }} />,
                        }}
                      />
                    </Text>
                  )}
                </Suspense>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
      {pinned && (
        <MessageBar
          delayedRender={false}
          isMultiline={false}
          actions={
            <ActionButton onClick={onUnpin} iconProps={{ iconName: "Unpin" }}>
              {t("actions.unpin", { ns: "common" })}
            </ActionButton>
          }
          styles={{ iconContainer: { lineHeight: 24, display: "none" }, innerText: { lineHeight: 23 } }}
        >
          <Suspense fallback={<Spinner />}>{t("tooltip.pinned", { ns: "annotations" })}</Suspense>
        </MessageBar>
      )}
    </Suspense>
  );
};

export const TooltipContentCompare: FC<{
  title: string;
  dates: string[];
  points: TooltipPoint[][];
  customTooltipNumbroOptions?: numbro.Format;
}> = ({ title, dates, points, customTooltipNumbroOptions }) => {
  const rows = points.reduce<Record<string, TooltipPoint>>((res, point) => {
    point.forEach(p => {
      if (p.id in res) return;
      res[p.id] = p;
    });
    return res;
  }, {});
  return (
    <Stack horizontal verticalAlign="start" tokens={{ childrenGap: 15, padding: 12 }}>
      {/* Start with the column that contains the name of each row */}
      <Stack tokens={{ childrenGap: 5 }}>
        <Stack.Item>
          <Text block nowrap className={styles.title}>
            {title}
          </Text>
        </Stack.Item>
        {Object.values(rows).map(p => {
          return (
            <Stack.Item key={`row_names_${p.id}`}>
              <Stack key={`row_names_with_color_${p.id}`} horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
                {p.squareComponent ?? (p.shape && getIconByShape(p.shape, p.color))}
                <Text block nowrap>
                  {p.title}
                </Text>
              </Stack>
            </Stack.Item>
          );
        })}
      </Stack>
      {/* Loop over each date and fill in their column values */}
      {dates.map((d: string, i: number) => {
        return (
          <Stack key={`date_column_${d}`} tokens={{ childrenGap: 5 }}>
            <Text block nowrap className={styles.title}>
              {d}
            </Text>
            {points[i].length > 0 ? (
              <>
                {points[i].map((p: TooltipPoint) => {
                  return (
                    <Text key={`tooltip_values_comparison_${p.id}_${p.date}`} block nowrap className={styles.value}>
                      {["string", "number"].includes(typeof p.value)
                        ? `${p.prefix || ""}${numbro(p.value).format(
                            customTooltipNumbroOptions ?? tooltipNumbroOptions,
                          )}${p.suffix || ""}`
                        : "N/A"}
                    </Text>
                  );
                })}
              </>
            ) : (
              <>
                <Text key={`zero`} block nowrap className={styles.value}>
                  {numbro(0).format(customTooltipNumbroOptions ?? tooltipNumbroOptions)}
                </Text>
              </>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export const TooltipContentYoY: FC<{
  title: string;
  dates: string[];
  points: TooltipPoint[][];
  customTooltipNumbroOptions?: numbro.Format;
}> = ({ title, dates, points, customTooltipNumbroOptions }) => {
  return (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 15, padding: 12 }}>
      {/* Start with the column that contains the name of each row */}
      <Stack tokens={{ childrenGap: 5 }}>
        <Stack.Item>
          <Text block nowrap className={styles.title}>
            {title}
          </Text>
        </Stack.Item>
        {(points[0].length > 0 ? points[0] : points[1]).map(p => {
          return (
            <Stack.Item key={`row_names_${p.id}`}>
              <Stack key={`row_names_with_color_${p.id}`} horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
                {p.squareComponent ?? (p.shape && getIconByShape(p.shape, p.color))}
                <Text block nowrap>
                  {p.title}
                </Text>
              </Stack>
            </Stack.Item>
          );
        })}
      </Stack>
      {/* Loop over each date and fill in their column values */}
      {dates.map((d: string, i: number) => {
        return (
          <Stack key={`date_column_${d}`} tokens={{ childrenGap: 5 }}>
            <Text block nowrap className={styles.title}>
              {d}
            </Text>
            {points[i].length > 0 ? (
              <>
                {points[i].map((p: TooltipPoint) => {
                  return (
                    <Text key={`tooltip_values_comparison_${p.id}_${p.date}`} block nowrap className={styles.value}>
                      {["string", "number"].includes(typeof p.value)
                        ? `${p.prefix || ""}${numbro(p.value).format(
                            customTooltipNumbroOptions ?? tooltipNumbroOptions,
                          )}${p.suffix || ""}`
                        : "N/A"}
                    </Text>
                  );
                })}
              </>
            ) : (
              <>
                <Text key={`zero`} block nowrap className={styles.value}>
                  {numbro(0).format(customTooltipNumbroOptions ?? tooltipNumbroOptions)}
                </Text>
              </>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export function tooltipContentDateComparison(
  d: string,
  graphLines: any[],
  palette: string[],
  prefix = "",
  suffix = "",
) {
  const currDate = moment(d, "YYYY-MM-DD");
  const points: TooltipPoint[] = graphLines.map((point: any) => {
    const findData = point.coordinates.find((i: Coordinate) => {
      // Search the lines for a similar x value for vertical shared tooltip
      return moment(i.dateToMatch, "YYYY-MM-DD").isSame(currDate, "month");
    });
    return {
      id: point.id,
      title: point.title,
      color: palette[point.idx],
      value: findData?.value,
      date: findData?.originalDate,
      previousPeriod: point.previousPeriod,
      prefix,
      suffix,
    };
  });

  const prevPoints = points.filter((p: TooltipPoint) => p.previousPeriod);
  const currPoints = points.filter((p: TooltipPoint) => !p.previousPeriod);
  return [prevPoints, currPoints];
}

interface Point {
  x?: number;
  y?: number;
}

export const VerticalIndicator: FC<{ from: Point; to: Point; circle?: Point }> = ({ from, to, circle }) => {
  return (
    <g>
      <Line from={from} to={to} stroke={"lightgray"} pointerEvents="none" />
      {circle && (
        <circle
          cx={circle.x}
          cy={circle.y}
          r={4}
          fill="transparent"
          stroke="white"
          strokeWidth={2}
          pointerEvents="none"
        />
      )}
    </g>
  );
};
