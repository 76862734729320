import moment from "moment";
import { FC, createContext, useContext, useEffect, useMemo, useState } from "react";

import { useEntityContext } from "../../../../../../context/EntityContext";
import useAdjustingEntries from "../../../../../hooks/adjusting-entries";
import { ReportingLine } from "../../../../../hooks/reporting-lines/types";
import useReportingLines from "../../../../../hooks/reporting-lines/useReportingLines";
import useMappings from "../../../../../hooks/useMappings";
import { Mapping } from "../../../../../hooks/useMappings/types";
import { useProfile } from "../../../../../hooks/useProfile";
import { User } from "../../../../../hooks/useProfile/types";

import { AdjustingEntry } from "./types";

interface ContextInterface {
  adjustingEntries?: AdjustingEntry[];
  adjEntByPeriod?: Record<string, AdjustingEntry[]>;
  loading: boolean;

  profile?: User;
  reportingLines?: ReportingLine[];
  mappings?: Mapping[];
  mappingIdsByAccAnaxId?: Map<string, string>;
  refreshMappings: () => Promise<void>;

  createAdjustingEntries: (adjustingEntries: AdjustingEntry[]) => void;
  replaceAdjustingEntries: (adjustingEntries: AdjustingEntry[]) => void;
  updateAdjustingEntry: (adjustingEntry: AdjustingEntry) => void;
  deleteAdjustingEntryById: (id: string) => void;
  changeAdjustingEntriesDescriptionName: (
    period: string,
    oldDescriptionName: string,
    newDescriptionName: string,
  ) => void;
  deleteAdjustingEntriesByDescription: (period: string, description: string) => void;
  updateAdjustingEntriesByPeriod: (oldPeriod: string, newPeriod: string) => void;
  deleteAdjustingEntriesByPeriod: (period: string) => void;
}

const AdjustingEntriesContext = createContext<ContextInterface>({
  loading: false,
  refreshMappings: () => new Promise(resolve => resolve),

  createAdjustingEntries: () => {},
  replaceAdjustingEntries: () => {},
  updateAdjustingEntry: () => {},
  deleteAdjustingEntryById: () => {},
  changeAdjustingEntriesDescriptionName: () => {},
  deleteAdjustingEntriesByDescription: () => {},
  updateAdjustingEntriesByPeriod: () => {},
  deleteAdjustingEntriesByPeriod: () => {},
});

export const AdjustingEntriesProvider: FC = ({ children }) => {
  const { selectedEntity } = useEntityContext();

  const { user: profile, loading: userLoading } = useProfile();
  const { reportingLines, loading: rlLoading } = useReportingLines({ entityId: selectedEntity });
  const { mappings, refetch: refreshMappings, loading: mappingsLoading } = useMappings({ entityId: selectedEntity });
  const { adjustingEntries: fetchedAdjustingEntries, loading: adjEntLoading } = useAdjustingEntries({
    entityId: selectedEntity,
  });
  const [adjustingEntries, setAdjustingEntries] = useState<AdjustingEntry[] | undefined>();

  const loading = userLoading || rlLoading || mappingsLoading || adjEntLoading;

  useEffect(() => {
    if (fetchedAdjustingEntries === undefined) return;
    setAdjustingEntries(fetchedAdjustingEntries);
  }, [fetchedAdjustingEntries]);

  const adjEntByPeriod = useMemo(() => {
    return adjustingEntries?.reduce<Record<string, AdjustingEntry[]>>((res, adjEnt) => {
      const date = moment(adjEnt.datePeriod).format("YYYY-MM");
      res[date] = [...(res[date] ?? []), adjEnt];
      return res;
    }, {});
  }, [adjustingEntries]);

  const mappingIdsByAccAnaxId = useMemo(() => {
    return mappings?.reduce<Map<string, string>>((res, map) => {
      const accountId = map.accountAnalyticalAxis.accountId;
      const anAxId = map.accountAnalyticalAxis.analyticalAxisId;
      res.set(`${accountId}${anAxId ?? ""}`, map.id);
      return res;
    }, new Map());
  }, [mappings]);

  // Functions to CRUD adjusting entries
  function createAdjustingEntries(adjustingEntries: AdjustingEntry[]) {
    setAdjustingEntries(adjEnts => [...adjustingEntries, ...(adjEnts ?? [])]);
  }

  function replaceAdjustingEntries(adjustingEntries: AdjustingEntry[]) {
    setAdjustingEntries(adjustingEntries);
  }

  function updateAdjustingEntry(adjustingEntry: AdjustingEntry) {
    setAdjustingEntries(adjEnts => {
      if (adjEnts === undefined) return;
      return adjEnts.map(aj => {
        if (aj.id !== adjustingEntry.id) return aj;
        return adjustingEntry;
      });
    });
  }

  function deleteAdjustingEntryById(id: string) {
    setAdjustingEntries(adjEnts => {
      if (adjEnts === undefined) return;
      return adjEnts.filter(aj => aj.id !== id);
    });
  }

  function changeAdjustingEntriesDescriptionName(
    period: string,
    oldDescriptionName: string,
    newDescriptionName: string,
  ) {
    setAdjustingEntries(adjEnts => {
      if (adjEnts === undefined) return;
      return adjEnts.map(aj => {
        if (!aj.datePeriod.startsWith(period) || aj.description !== oldDescriptionName) return aj;
        return {
          ...aj,
          description: newDescriptionName,
        };
      });
    });
  }

  function deleteAdjustingEntriesByDescription(period: string, description: string) {
    setAdjustingEntries(adjEnts => {
      if (adjEnts === undefined) return;
      return adjEnts.filter(aj => !(aj.datePeriod.startsWith(period) && aj.description === description));
    });
  }

  function updateAdjustingEntriesByPeriod(oldPeriod: string, newPeriod: string) {
    setAdjustingEntries(adjEnts => {
      if (adjEnts === undefined) return;
      return adjEnts.map(aj => {
        if (!aj.datePeriod.startsWith(oldPeriod)) return aj;
        return {
          ...aj,
          datePeriod: newPeriod,
        };
      });
    });
  }

  function deleteAdjustingEntriesByPeriod(period: string) {
    setAdjustingEntries(adjEnts => {
      if (adjEnts === undefined) return;
      return adjEnts.filter(aj => !aj.datePeriod.startsWith(period));
    });
  }

  const defaultContext: ContextInterface = {
    adjustingEntries,
    adjEntByPeriod,
    loading,

    profile,
    reportingLines,
    mappings,
    mappingIdsByAccAnaxId,
    refreshMappings,

    // adjusting entry
    createAdjustingEntries,
    replaceAdjustingEntries,
    updateAdjustingEntry,
    deleteAdjustingEntryById,

    // description
    changeAdjustingEntriesDescriptionName,
    deleteAdjustingEntriesByDescription,

    // period
    updateAdjustingEntriesByPeriod,
    deleteAdjustingEntriesByPeriod,
  };
  return <AdjustingEntriesContext.Provider value={defaultContext}>{children}</AdjustingEntriesContext.Provider>;
};

export function useAdjustingEntriesContext(): ContextInterface {
  return useContext(AdjustingEntriesContext);
}
