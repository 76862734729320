export enum JournyEvents {
  LoggedIn = "Logged in",
  LoggedOut = "Logged out",
  KeyMetricsClicked = "Key metrics edit button clicked",
  KeyMetricsCardClicked = "Key metrics card clicked",
  ScenarioChanged = "Selected scenario changed",
  SaveAdjustingEntriesClicked = "Save adjusting entries clicked",
  COGSCreated = "COGS module created",
  InventoryCreated = "Inventory module created",
  PersonnelCostsCreated = "Personnel Costs module created",
  ProjectRevenueCreated = "Project Revenue module created",
  PxQCreated = "PxQ module created",
  SimpleBudgetQuickStartClicked = "Simple budget quick start clicked",
  SimpleBudgetSaved = "Simple budget saved",
  SimpleBudgetFromCSVCreated = "Simple budget from CSV created",
  RecurringRevenueCreated = "Recurring revenue module created",
  VATCreated = "VAT created",
  CRMCreated = "CRM created",
  CreateScenarioClicked = "Create scenario clicked",
  CreateNewForecastClicked = "Create new forecast clicked",
  CreateBVClicked = "Create budget version clicked",
  RLChanged = "Reporting line changed",
  AcceptInvitationClicked = "Invitation accepted",
  AddTeamClicked = "Add team clicked",
  InviteMemberClicked = "Invite member clicked",
  BudgetCSVClicked = "Upload budget from CSV clicked",
  BudgetCopiedFromPrevious = "Simple budget copied",
  IncludeBudgetFromCurrentScenario = "Include budgets from scenario",
  ExistingSimpleBudgetOpened = "Simple budget re-opened",
  ExistingPxQBudgetOpened = "PxQ budget re-opened",
  ExistingCOGSBudgetOpened = "COGS budget re-opened",
  ExistingInventoryBudgetOpened = "Inventory budget re-opened",
  ExistingRecurringRevenueBudgetOpened = "Rec rev budget re-opened",
  ExistingCRMBudgetOpened = "CRM budget re-opened",
  ExistingPersonnelCostsBudgetOpened = "Personnel costs budget re-opened",
  ExistingProjectRevenueBudgetOpened = "Project budget re-opened",
  BudgetAccountAddedInModule = "Budget account added in module",
  BudgetAlteredInMainScreen = "Budget altered in main screen",
  MappingAlteredOnBudgetLine = "Mapping altered on budget line",
  CSVUploadSimpleBudgetStopped = "CSV upload budget stopped",
  BudgetAccountRenamed = "Budget account renamed",
  ApplyToBudgetAccountClicked = "Apply to budget account clicked",
  SimpleBudgetExport = "Export simple budget clicked",

  // Integrations
  BookkeepingIntegrationAdded = "Bookkeeping int. added",
  CRMIntegrationAdded = "CRM int. added",
  ProjectIntegrationAdded = "Project int. added",
  SubscriptionIntegrationAdded = "Subscription int. added",
  PersonnelCostsIntegrationAdded = "Personnel costs int. added",
  HistoricOutstandingItemIntegrationAdded = "Hist outstanding item int. added",

  // Reporting
  MappingCreated = "Mapping created",
  ReportingTemplateSelected = "Reporting template selected",
  ReportingTemplateAdded = "Reporting template added",
  EntityCustomMetricsSaved = "Entity - Custom Metrics Saved",
  OrganisationCustomMetricsSaved = "Organisation - CustMet Saved",

  // Entity
  EntitySetYTD = "Set YTD",
  EntitySetLDA = "Set LDA",
  EntitySetYTG = "Set YTG",
  EnableEntityClicked = "Enable entity clicked",
  DisableEntityClicked = "Disable entity clicked",

  // PDF Export
  PdfExport = "PDF export created",
  SaveTemplate = "Save a template for pdf export",
}

export enum JournyScreens {
  ReportingTemplates = "Reporting - Templates",
  DashboardInventory = "Dashboard - Inventory",
  DashboardKeyMetrics = "Dashboard - Key Metrics",
  DashboardOutstandingItems = "Dashboard - Outstanding Items",
  DashboardPersonnelCosts = "Dashboard - Personnel Costs",
  DashboardRevenue = "Dashboard - Revenue",
  AdjustingEntries = "Adjusting entries",
  CustomMetrics = "Custom Metrics",
  DefinitionsForecasts = "Definitions - Forecasts",
  ForecastsBudgetAccounts = "Forecasts - Budget Accounts",
  ForecastsSettings = "Forecasts - Settings",
  COGSStepOne = "COGS - Step one",
  COGSStepTwo = "COGS - Step two",
  COGSStepThree = "COGS - Step three",
  COGSStepFour = "COGS - Step four",
  InventoryStepOne = "Inventory - Step one",
  InventoryStepTwo = "Inventory - Step two",
  InventoryStepThree = "Inventory - Step three",
  InventoryStepFour = "Inventory - Step four",
  PersonnelCostsStepOne = "Personnel Costs - Step one",
  PersonnelCostsStepTwo = "Personnel Costs - Step two",
  PersonnelCostsStepThree = "Personnel Costs - Step three",
  PersonnelCostsStepFour = "Personnel Costs - Step four",
  PersonnelCostsStepFive = "Personnel Costs - Step five",
  ProjectRevenueStepOne = "Project Revenue - Step one",
  ProjectRevenueStepTwo = "Project Revenue - Step two",
  ProjectRevenueStepThree = "Project Revenue - Step three",
  ProjectRevenueStepFour = "Project Revenue - Step four",
  ProjectRevenueStepFive = "Project Revenue - Step five",
  PxQStepOne = "PxQ - Step one",
  PxQStepTwo = "PxQ - Step two",
  PxQStepThree = "PxQ - Step three",
  PxQStepFour = "PxQ - Step four",
  RecurringRevenueStepOne = "Recurring Revenue - Step one",
  RecurringRevenueStepTwo = "Recurring Revenue - Step two",
  RecurringRevenueStepThree = "Recurring Revenue - Step three",
  RecurringRevenueStepFour = "Recurring Revenue - Step four",
  RecurringRevenueStepFive = "Recurring Revenue - Step five",
  SimpleBudget = "Simple Budget",
  VatBudgetStepOne = "VAT CF - Step one",
  VatBudgetStepTwo = "VAT CF - Step two",
  VatBudgetStepThree = "VAT CF - Step three",
  VatBudgetStepFour = "VAT CF - Step four",
  ReportingAccounts = "Reporting - Accounts",
  ReportingAnAxes = "Reporting - Analytical Axes",
  ReportingMapping = "Reporting - Mapping",
  ReportingCF = "Reporting - Cashflow",
  DefinitionsValidations = "Definitions - Validations",

  // Integrations
  IntegrationsBookkeeping = "Integrations - Bookkeeping",
  IntegrationsCRM = "Integrations - CRM",
  IntegrationsOther = "Integrations - Other",
  IntegrationsProject = "Integrations - Project",
  IntegrationsHistoricOutstandingItems = "Integrations - Outstanding Items",
  IntegrationsSubscription = "Integrations - Subscription",
  IntegrationsPersonnelCosts = "Integrations - Personnel Costs",

  // Organisation
  OrganisationSettings = "Organisation - Settings",
  Billing = "Organisation - Billing",

  // Entity
  OrganisationEntities = "Organisation - Entities",
  // PDF Export
  PdfExport = "PDF Export",

  // Teams
  Teams = "Teams",
  AddTeam = "Teams - Add Team",
  InviteMembers = "Teams - Invite Members",

  // Onboarding
  // Onboarding - Organisation
  OnboardingOrganisationIntegration = "Onboarding - Connect integration",
  OnboardingOrganisationSelectEntities = "Onboarding - Select entities",
  OnboardingOrganisationSelectPriority = "Onboarding - Select priority",

  // Onboarding - Entity
  OnboardingEntityDates = "Onboarding - Dates",
  OnboardingEntityReporting = "Onboarding - Reporting",
  OnboardingEntityReportingCustomMetrics = "Onboarding - Custom Metrics",
  OnboardingEntityMappingAnalyticalAxes = "Onboarding - Analytical Axis",
  OnboardingEntityMapping = "Onboarding - Mapping",
  OnboardingEntityDashboard = "Onboarding - Dashboard",
}
