import { Navigate, useLocation } from "react-router-dom";

import { useAuthentication } from "../context/AuthenticationContext";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useAuthentication();
  const location = useLocation();

  // TODO: Use "from" state
  if (!isAuthenticated) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;
