import { FC } from "react";
import { Navigate } from "react-router";

import { useDashboardContext } from "../context/DashboardContext";

interface Props {
  children: JSX.Element;
}

export const OrganisationRoute: FC<Props> = props => {
  const { organisations } = useDashboardContext();

  if (organisations.length === 0) return <Navigate to="./profile/organisations" />;
  return props.children;
};
