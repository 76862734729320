import axios from "axios";
import useAxios from "axios-hooks";
import { useCallback, useMemo } from "react";

import { objectsToMap } from "../../../util/utils";

import { Mapping, PatchEntityMapping, PatchEntityMappingResponse } from "./types";

interface Params {
  entityId?: string;
  ignoreEntityAxis?: boolean;
}

const useMappings = ({ entityId, ignoreEntityAxis }: Params) => {
  const [{ data: mappings, error, loading }, refetchMappingsAxios] = useAxios<Mapping[]>(
    {
      url: `/entities/${entityId}/mappings`,
      params: {
        ignoreEntityAxis,
      },
    },
    { manual: entityId === undefined },
  );

  const refetch = useCallback(async () => {
    await refetchMappingsAxios();
  }, [refetchMappingsAxios]);

  const patch = useCallback(
    async (actions: PatchEntityMapping[]) => {
      const res = await axios.patch<PatchEntityMappingResponse[]>(`/entities/${entityId}/mappings/batch`, actions);
      return res.data;
    },
    [entityId],
  );

  const mappingsById = useMemo(
    () => (mappings ? objectsToMap<Mapping, Mapping["id"]>(mappings, "id") : undefined),
    [mappings],
  );

  if (error !== null) throw error;

  return { mappings, mappingsById, loading, refetch, patch };
};

export default useMappings;
