import numbro from "numbro";

import { Currency } from "../types/entity";

import { assertUnreachable } from "./utils";

function getDecimalSeparator(): string {
  // Try to determine the decimal separator based on locale
  return numbro(1.2)
    .format()
    .replace(/.*1(.*)2.*/, "$1");
}

function getThousandsSeparator(): string {
  // Try to determine the thousands separator based on locale
  return numbro(1234)
    .format({ thousandSeparated: true })
    .replace(/.*1(.*)2.*/, "$1");
}

// You can give an undefined to numbro which will result in a 0
export function formatCentToDecimal(n?: string | number, divideBy?: number, multiplier?: number) {
  return numbro(n)
    .divide(divideBy || 100)
    .multiply(multiplier || 1)
    .value();
}

export function round(number: number, decimals: number = 0): number {
  return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export function parseLocaleFloat(
  str: string,
  customThousandSeparator?: string,
  customDecimalSeparator?: string,
): number | null {
  const decimalSeparator = getDecimalSeparator();
  const thousandSeparator = getThousandsSeparator();
  const valueToParse = str
    .replaceAll(customThousandSeparator ?? thousandSeparator, "")
    .replace(customDecimalSeparator ?? decimalSeparator, ".");

  const numValue = parseFloat(valueToParse);
  if (isNaN(numValue)) return null;
  return numValue;
}

export function calculateCardinality(val: number): number {
  return Math.max(Math.pow(1000, Math.floor(Math.log10(Math.abs(val) / 10) / 3)), 1);
}

export function formatCurrency(value: number, currency?: Currency, numbroOptions?: numbro.Format): string {
  return numbro(value).formatCurrency({
    thousandSeparated: true,
    currencySymbol: currency ? currencyToSymbol(currency) : "€",
    currencyPosition: "prefix",
    mantissa: 2,
    spaceSeparated: true,
    optionalMantissa: true,
    ...(numbroOptions ?? {}),
  });
}

export function formatPercentage(value: number): string {
  return numbro(value).format({
    postfix: " %",
    mantissa: 0,
  });
}

export function currencyToSymbol(currency?: Currency): string {
  const completeCurrency = currency ?? Currency.EUR;
  switch (completeCurrency) {
    case Currency.AUD:
      return "A$";
    case Currency.USD:
      return "$";
    case Currency.GBP:
      return "£";
    case Currency.EUR:
      return "€";
    case Currency.SEK:
      return "kr";
    case Currency.BRL:
      return "R$";
    default:
      assertUnreachable(completeCurrency);
  }
}
