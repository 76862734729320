import {
  IColumn,
  IObjectWithKey,
  ISelection,
  IconButton,
  Modal,
  Persona,
  PrimaryButton,
  Selection,
  SelectionMode,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Organisation } from "../../../../../../../types/organisation";
import AreYouSure from "../../../../../../atoms/AreYouSure";
import EmptyState from "../../../../../../molecules/EmptyState";
import UniformDetailsList from "../../../../../../molecules/UniformDetailsList";

interface Props {
  onAdd: (organisationIds: string[]) => void;
  onDismiss: VoidFunction;
  organisations: Organisation[];
}

const AddOrganisationModal: FC<Props> = ({ onAdd, onDismiss, organisations }) => {
  const { t } = useTranslation(["billing"]);
  const theme = useTheme();
  const [selectedOrganisations, setSelectedOrganisations] = useState<Organisation[]>([]);
  const [openAreYouSure, setOpenAreYouSure] = useState<boolean>(false);

  const selection = new Selection<Organisation>({
    getKey: organisation => organisation.id,
    onSelectionChanged: () => {
      if (selection.getSelectedCount() > 0) setSelectedOrganisations(selection.getSelection());
      else setSelectedOrganisations([]);
    },
  });

  const columns: IColumn[] = [
    {
      key: "info",
      name: "Organisation",
      minWidth: 200,
      onRender: (item?: Organisation) => {
        if (item === undefined) return null;
        return <Persona text={item.name} secondaryText={item.vat_number ?? undefined} />;
      },
    },
  ];
  return (
    <Modal isOpen onDismiss={onDismiss}>
      <IconButton
        iconProps={{
          iconName: "Cancel",
        }}
        onClick={onDismiss}
        styles={{ root: { position: "absolute", top: theme.spacing.s1, right: theme.spacing.s1 } }}
      />
      <Stack tokens={{ padding: theme.spacing.l2, childrenGap: theme.spacing.m }}>
        <Text variant="xLargePlus">{t("paymentExchange.modal.title", { ns: "billing" })}</Text>
        {organisations.length === 0 ? (
          <EmptyState
            title={t("paymentExchange.modal.emptyState.title", { ns: "billing" })}
            explainer={t("paymentExchange.modal.emptyState.explainer", { ns: "billing" })}
          />
        ) : (
          <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
            <Stack
              styles={{ root: { flex: 1, minHeight: 0, overflowY: "auto", width: 350 } }}
              data-is-scrollable="true"
            >
              <UniformDetailsList
                useNativeSticky
                items={organisations}
                columns={columns}
                selection={selection as unknown as ISelection<IObjectWithKey>}
                selectionMode={SelectionMode.multiple}
              />
            </Stack>
            <PrimaryButton
              text={t("paymentExchange.modal.addButton", { ns: "billing", count: selectedOrganisations.length })}
              disabled={selectedOrganisations.length === 0}
              onClick={() => setOpenAreYouSure(true)}
            />
          </Stack>
        )}
      </Stack>
      {openAreYouSure && (
        <AreYouSure
          title={t("paymentExchange.modal.areYouSure.title", { ns: "billing" })}
          explainer={t("paymentExchange.modal.areYouSure.explainer", {
            ns: "billing",
          })}
          onApproval={async () => {
            await onAdd(selectedOrganisations.map(org => org.id));
            setOpenAreYouSure(false);
          }}
          onDismiss={() => setOpenAreYouSure(false)}
          isDangerous
          customWidth={400}
        />
      )}
    </Modal>
  );
};

export default AddOrganisationModal;
