import axios from "axios";
import useAxios from "axios-hooks";

import { PutCustomMetric } from "../types";

import { CustomMetric } from "./types";

interface Params {
  entityId?: string;
  manual?: boolean;
}

export function useCustomMetrics({ entityId, manual }: Params) {
  const [{ data: customMetrics, loading, error }, refetch] = useAxios<CustomMetric[]>(
    {
      url: `/entities/${entityId}/custom-metrics`,
    },
    { manual: entityId === undefined || manual },
  );

  async function put(customMetrics: CustomMetric[]) {
    if (entityId === undefined) throw new Error("Entity id was undefined");
    await axios.put<PutCustomMetric[]>(
      `/entities/${entityId}/custom-metrics`,
      customMetrics.filter(cm => cm.reportingLineId !== null),
    );
  }

  if (error !== null) throw error;

  return {
    customMetrics,
    loading,
    refetch,
    put,
  };
}
