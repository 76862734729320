import useAxios from "axios-hooks";

interface Params {
  organisationId?: string;
}

const useStripe = ({ organisationId }: Params) => {
  const [{ error }, createCustomerPortalSession] = useAxios<string>(
    {
      method: "POST",
      url: `/organisations/${organisationId}/create-customer-portal-session`,
    },
    { manual: true },
  );

  if (error !== null) throw error;

  return { createCustomerPortalSession };
};

export default useStripe;
