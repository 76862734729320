import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useDashboardContext } from "../../../context/DashboardContext";
import { useEntityContext } from "../../../context/EntityContext";
import { Permission, SubscriptionTier } from "../../../types/organisation";
import EmptyState from "../EmptyState";

import UpsellScreen from "./UpsellScreen";

interface Props {
  requiredTier: SubscriptionTier;
  permissions: Permission[];
  children: JSX.Element | JSX.Element[];
}

const Protected: FC<Props> = ({ requiredTier, permissions, children }) => {
  const { t } = useTranslation(["permissions"]);

  const { organisations, selectedOrganisation } = useDashboardContext();
  const organisation = organisations.find(o => o.id === selectedOrganisation);

  const { entities, selectedEntity } = useEntityContext();
  const entity = entities.find(e => e.id === selectedEntity);

  const authorized = useMemo(() => {
    if (entity === undefined || organisation === undefined) return false;

    const grantedPermissions = new Set([...entity.permissions, ...organisation.permissions]);

    return permissions.every(requiredPermission => grantedPermissions.has(requiredPermission));
  }, [entity, organisation, permissions]);

  if (entity === undefined || organisation === undefined) return null;

  // TODO: Replace by upsell screen
  if (requiredTier === SubscriptionTier.Premium && organisation?.tier === SubscriptionTier.Lite)
    return <UpsellScreen />;

  if (!authorized) {
    return (
      <EmptyState
        title={t("unauthorized.page.title", { ns: "permissions" })}
        explainer={t("unauthorized.page.explainer", { ns: "permissions" })}
        iconName="HandsFree"
      />
    );
  }

  return <>{children}</>;
};

export default Protected;
