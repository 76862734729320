import { AxiosPromise } from "axios";
import useAxios from "axios-hooks";
import moment from "moment";
import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";

import { Aggregation } from "../components/hooks/useReports/types";
import { ReportPeriod, SetState } from "../types/common";
import { Entity } from "../types/entity";
import { Organisation, OrganisationType } from "../types/organisation";
import {
  OrganisationOnboardingProgress,
  OrganisationOnboardingProgressState,
  getOrganisationOnboardingProgress,
} from "../util/onboarding";

interface LocalOrganisationSetting {
  cardinality: number;
  reportingPeriod?: ReportPeriod;
  reportingAggregation?: Aggregation;
}
interface LocalOrganisationSettings extends Record<string, LocalOrganisationSetting> {}

interface ContextInterface {
  selectedOrganisation: Organisation["id"];
  setSelectedOrganisation: SetState<string>;
  organisations: Organisation[];
  refetchOrganisations: () => AxiosPromise<OrganisationsResponse>;
  loading: boolean;
  onboardingProgress: OrganisationOnboardingProgress | undefined;
  consolidationIsActive: boolean;
  updateConsolidationState: (isActive: boolean) => void;
  cardinality: number;
  setCardinality: (newCardinality: number) => void;
  getReportingPeriod: (entities?: Entity[]) => ReportPeriod;
  reportPeriodChanged: boolean;
  setReportingPeriod: (newReportPeriod?: ReportPeriod) => void;
  mappingValidationIsOK: boolean;
  reportingAggregation?: Aggregation;
  setReportingAggregation: (newReportAggregation?: Aggregation) => void;
  partOfAccountant: boolean;
}

interface OrganisationsResponse {
  organisations: Organisation[];
}

const defaultLocalOrganisationSettings: LocalOrganisationSetting = { cardinality: 1000 };
const getOrganisationReportPeriod = (entities?: Entity[]): ReportPeriod => {
  const fallback = {
    start: moment().startOf("year").format("YYYY-MM-DD"),
    lda: moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
    end: moment().endOf("year").format("YYYY-MM-DD"),
  };

  if (entities === undefined || entities.length === 0) return fallback;
  return {
    start: moment.min(entities.map(e => moment(e.ytd_start ?? fallback.start))).format("YYYY-MM-DD"),
    lda: moment.min(entities.map(e => moment(e.last_date_actuals ?? fallback.lda).endOf("day"))).format("YYYY-MM-DD"),
    end: moment.min(entities.map(e => moment(e.ytg_end ?? fallback.end))).format("YYYY-MM-DD"),
  };
};

const DashboardContext = createContext<ContextInterface>({
  selectedOrganisation: "",
  setSelectedOrganisation: () => {
    return;
  },
  organisations: [],
  refetchOrganisations: () => new Promise(resolve => resolve),
  loading: true,
  onboardingProgress: undefined,
  consolidationIsActive: false,
  updateConsolidationState: () => {},
  cardinality: defaultLocalOrganisationSettings.cardinality,
  setCardinality: () => {},
  getReportingPeriod: (entities?: Entity[]) => getOrganisationReportPeriod(entities),
  reportPeriodChanged: false,
  setReportingPeriod: () => {},
  mappingValidationIsOK: true,
  reportingAggregation: {
    type: "period",
    value: "month",
  },
  setReportingAggregation: () => {},
  partOfAccountant: false,
});

export const DashboardProvider: FC = ({ children }) => {
  const storedSelectedOrganisation = window.localStorage.getItem("selectedOrganisation") || undefined;
  const navigate = useNavigate();
  const location = useLocation();
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState<string>(storedSelectedOrganisation || "");
  const [localSettings, setLocalSettings] = useLocalStorage<LocalOrganisationSettings>("settings:organisations");
  const [loading, setLoading] = useState(true);
  const [onboardingProgress, setOnboardingProgress] = useState<OrganisationOnboardingProgress>();
  const [consolidationIsActive, setConsolidationIsActive] = useState<boolean>(
    JSON.parse(window.localStorage.getItem("consolidationIsActive") ?? "false"),
  );
  const [partOfAccountant, setPartOfAccountant] = useState<boolean>(false);

  const [{ data: organisationsData, loading: organisationsLoading, error: organisationsError }, refetchOrganisations] =
    useAxios<OrganisationsResponse>({ url: `/users/organisations` });

  const [{ data: mappingValidation }] = useAxios<boolean>(
    {
      url: `/organisations/${selectedOrganisation}/validations/mapping`,
    },
    { manual: !consolidationIsActive },
  );

  const mappingValidationIsOK = useMemo(() => {
    if (mappingValidation === undefined) return true;
    return mappingValidation;
  }, [mappingValidation]);

  const shouldCheckOnboardingNavigation = useMemo(() => {
    const exclusionPaths = [
      "/dashboard/profile",
      "/dashboard/profile/organisations",
      "/dashboard/invites",
      "/dashboard/organisations/billing",
    ];
    return !exclusionPaths.some(path => location.pathname.includes(path));
  }, [location]);

  const getLocalSettings = useCallback(
    (organisationId?: string): LocalOrganisationSetting => {
      if (organisationId === undefined || localSettings === undefined || localSettings[organisationId] === undefined)
        return defaultLocalOrganisationSettings;

      return localSettings[organisationId];
    },
    [localSettings],
  );

  const storeLocalSettings = useCallback(
    (organisationId: string, value: Partial<LocalOrganisationSetting>) => {
      setLocalSettings({
        ...localSettings,
        [organisationId]: {
          ...(localSettings ?? {})[organisationId],
          ...value,
        },
      });
    },
    [localSettings, setLocalSettings],
  );

  const cardinality = useMemo(() => {
    const localSettings = getLocalSettings(selectedOrganisation);
    if (localSettings.cardinality === undefined) return defaultLocalOrganisationSettings.cardinality;
    return localSettings.cardinality;
  }, [getLocalSettings, selectedOrganisation]);

  const setCardinality = useCallback(
    (newCardinality: number) => {
      if (selectedOrganisation === undefined) return;
      storeLocalSettings(selectedOrganisation, { cardinality: newCardinality });
    },
    [selectedOrganisation, storeLocalSettings],
  );

  const getReportingPeriod = useCallback(
    (entities?: Entity[]) => {
      const localSettings = getLocalSettings(selectedOrganisation);
      if (localSettings.reportingPeriod === undefined) {
        return getOrganisationReportPeriod(entities);
      }
      return localSettings.reportingPeriod;
    },
    [selectedOrganisation, getLocalSettings],
  );

  const reportPeriodChanged = useMemo(() => getLocalSettings().reportingPeriod !== undefined, [getLocalSettings]);

  const setReportingPeriod = useCallback(
    (newReportingPeriod?: ReportPeriod) => {
      if (selectedOrganisation === undefined) return;
      storeLocalSettings(selectedOrganisation, { reportingPeriod: newReportingPeriod });
    },
    [selectedOrganisation, storeLocalSettings],
  );

  const reportingAggregation: Aggregation = useMemo(() => {
    const localSettings = getLocalSettings(selectedOrganisation);
    if (localSettings.reportingAggregation === undefined) {
      // Fallback to month if no aggregation is found in local storage
      return { type: "period", value: "month" };
    }
    return localSettings.reportingAggregation;
  }, [getLocalSettings, selectedOrganisation]);

  const setReportingAggregation = useCallback(
    (newReportingAggregation?: Aggregation) => {
      if (selectedOrganisation === undefined) return;
      storeLocalSettings(selectedOrganisation, { reportingAggregation: newReportingAggregation });
    },
    [selectedOrganisation, storeLocalSettings],
  );

  useEffect(() => {
    if (typeof organisationsData === "undefined") return;

    const { organisations } = organisationsData;
    let newSelectedOrganisation = selectedOrganisation;

    if (!selectedOrganisation && organisations.length > 0) {
      newSelectedOrganisation = organisations[0].id;
      setSelectedOrganisation(newSelectedOrganisation);
    }
    // Make sure the stored selected organistion is an organisation that you have access to
    else if (organisations.length > 0 && organisations.findIndex(o => o.id === selectedOrganisation) === -1) {
      newSelectedOrganisation = organisations[0].id;
      setSelectedOrganisation(newSelectedOrganisation);
    }
    const selectedOrg = organisations.find(o => o.id === newSelectedOrganisation);
    // Make sure that when the user turns off consolidation in the Organisation Settings, we set this state properly so that the sidebar and theme changes
    if (selectedOrg && selectedOrg.settings?.consolidation)
      setConsolidationIsActive(consolidationIsActive && selectedOrg.settings?.consolidation?.is_active);
    setOrganisations(organisations);
    setPartOfAccountant(organisations.some(o => o.organisation_type === OrganisationType.Accountant));
  }, [organisationsData, selectedOrganisation, consolidationIsActive]);

  useEffect(() => {
    // When "loading = true" we will show a spinner and components will unmount.
    // This might lead to unwanted behaviour as state will be destroyed of child components
    // Make sure you know what you are doing when setting "loading = true"
    setLoading(organisationsLoading);
  }, [organisationsLoading]);

  // Persist selection to local storage
  useEffect(() => {
    if (!selectedOrganisation) return window.localStorage.removeItem("selectedOrganisation");
    window.localStorage.setItem("selectedOrganisation", selectedOrganisation);
  }, [navigate, selectedOrganisation]);

  // Set the onboarding progress when the organisation changes
  useEffect(() => {
    // Reset onboarding progress when selected organisation changes
    // As a side-effect this will cause a refresh for the entire dashboard but is necessary to reload the onboarding
    setOnboardingProgress(undefined);
    // Check onboarding progress
    getOrganisationOnboardingProgress(selectedOrganisation).then(setOnboardingProgress);
  }, [selectedOrganisation]);

  // Check if we should navigate based on onboarding progress
  useEffect(() => {
    if (!shouldCheckOnboardingNavigation) return;
    if (onboardingProgress !== undefined && onboardingProgress.state !== OrganisationOnboardingProgressState.Done)
      return navigate("/onboarding", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingProgress, shouldCheckOnboardingNavigation]);

  if (organisationsError) throw organisationsError;

  function updateConsolidationState(isActive: boolean) {
    setConsolidationIsActive(isActive);
    window.localStorage.setItem("consolidationIsActive", JSON.stringify(isActive));
  }

  const defaultContext: ContextInterface = {
    selectedOrganisation,
    setSelectedOrganisation,
    organisations,
    refetchOrganisations,
    loading,
    onboardingProgress,
    consolidationIsActive,
    updateConsolidationState,
    cardinality,
    setCardinality,
    getReportingPeriod,
    reportPeriodChanged,
    setReportingPeriod,
    mappingValidationIsOK,
    reportingAggregation,
    setReportingAggregation,
    partOfAccountant,
  };

  return <DashboardContext.Provider value={defaultContext}>{children}</DashboardContext.Provider>;
};

export function useDashboardContext(): ContextInterface {
  return useContext(DashboardContext);
}
