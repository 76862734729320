import { DefaultButton, Modal, PrimaryButton, Spinner, Stack, Text, useTheme } from "@fluentui/react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import DangerousButton from "../DangerousButton";

interface Props {
  title: string;
  explainer: string;
  customContent?: JSX.Element;
  onApproval: () => void;
  onDismiss: () => void;
  isDangerous?: boolean;
  customWidth?: number;
}

const AreYouSure: FC<Props> = ({
  title,
  explainer,
  customContent,
  onApproval,
  onDismiss,
  isDangerous = false,
  customWidth = 350,
}) => {
  const { t } = useTranslation(["common"]);
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const ActionButton = isDangerous ? DangerousButton : PrimaryButton;

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      styles={{ main: { padding: theme.spacing.l1, width: customWidth, minHeight: 0 } }}
      isBlocking={false}
    >
      <Text variant="xLargePlus">{title}</Text>
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        {customContent}
        <Text>{explainer}</Text>
        <Stack horizontal verticalAlign="end" horizontalAlign="end" tokens={{ childrenGap: theme.spacing.s1 }}>
          <DefaultButton text={t("no", { ns: "common" })} onClick={onDismiss} />
          <ActionButton
            onClick={async () => {
              setLoading(true);
              await onApproval();
              setLoading(false);
            }}
            disabled={loading}
          >
            {loading ? <Spinner /> : t("yes", { ns: "common" })}
          </ActionButton>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AreYouSure;
