import {
  ConstrainMode,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IDetailsFooterProps,
  IDetailsHeaderProps,
  IDetailsListProps,
  IDetailsRowStyles,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  useTheme,
} from "@fluentui/react";
import moment from "moment";
import { FC, useMemo, useState } from "react";

import { get } from "../../../util/utils";

import { Props } from "./index.types";

const UniformDetailsList: FC<Props> = props => {
  const theme = useTheme();
  const {
    items,
    columns,
    setColumns,
    isLoading = false,
    footer,
    skipDataSort,
    useNativeSticky = false,
    headerStyles,
    ...otherProps
  } = props;

  const [currentItems, setCurrentItems] = useState<any[]>([]);

  useMemo(() => {
    if (items.length === 0) return;

    if (!columns || skipDataSort) {
      setCurrentItems(items);
      return;
    }

    const currColumn = columns.find((col: IColumn) => col.isSorted);
    if (!currColumn) {
      setCurrentItems(items);
      return;
    }

    const newItems = copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
    setCurrentItems(newItems);
  }, [items, columns, skipDataSort]);

  const onRenderRow: IDetailsListProps["onRenderRow"] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (typeof props === "undefined") return null;

    if (props.item.error) {
      customStyles.root = { backgroundColor: theme.semanticColors.errorBackground };
    }

    return (
      <DetailsRow
        {...props}
        styles={{
          ...props.item.styles,
          ...customStyles,
          root: { display: "flex", alignItems: "center" },
          fields: { display: "flex", alignItems: "center" },
        }}
      />
    );
  };

  function onRenderDetailsHeader(
    props?: IDetailsHeaderProps,
    defaultRender?: (props?: IDetailsHeaderProps) => JSX.Element | null,
  ) {
    if (!props || !defaultRender) return null;
    const styledProps = { ...props, headerStyles };

    if (useNativeSticky) return defaultRender(styledProps);
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        {defaultRender(styledProps)}
      </Sticky>
    );
  }

  function onRenderDetailsFooter(footerItem: unknown, detailsFooterProps?: IDetailsFooterProps): JSX.Element {
    const detailsRow = (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps ? detailsFooterProps.columns : undefined}
        item={footerItem}
        itemIndex={-1}
      />
    );

    if (useNativeSticky) {
      return <div style={{ position: "sticky", bottom: 0, left: 0 }}>{detailsRow}</div>;
    } else {
      return (
        <Sticky stickyPosition={StickyPositionType.Footer} isScrollSynced>
          {detailsRow}
        </Sticky>
      );
    }
  }

  function onColumnClick(_?: React.MouseEvent<HTMLElement>, column?: IColumn): void {
    if (column === undefined || columns === undefined || setColumns === undefined) return;

    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = false;
      }
    });

    setColumns(newColumns);
    if (skipDataSort) return;
    const newItems = copyAndSort(currentItems, currColumn.fieldName, currColumn.isSortedDescending);
    setCurrentItems(newItems);
  }

  function copyAndSort(items: any[], columnKey?: string, isSortedDescending?: boolean): any[] {
    if (columnKey === undefined) return items;

    // By default FluintUI does not support subproperty selection. With the custom get function,
    // subproperty selection is enabled.
    const firstItem = items.find((x: any) => get(x, columnKey));
    if (!firstItem) return items;
    let newItems = [];
    switch (typeof get(firstItem, columnKey)) {
      case "string":
        newItems = items.slice(0).sort((a: any, b: any) => {
          if (!get(a, columnKey)) return -1;
          if (!get(b, columnKey)) return 1;
          return get(a, columnKey).toLowerCase() < get(b, columnKey).toLowerCase() ? 1 : -1;
        });
        break;
      case "boolean":
      case "number":
        newItems = items.slice(0).sort((a: any, b: any) => {
          if (!get(a, columnKey)) return -1;
          if (!get(b, columnKey)) return 1;
          return get(a, columnKey) < get(b, columnKey) ? 1 : -1;
        });
        break;
      case "object":
        if (get(firstItem, columnKey) instanceof Date) {
          newItems = items.slice(0).sort((a: any, b: any) => {
            if (!get(a, columnKey)) return -1;
            if (!get(b, columnKey)) return 1;
            return moment(get(a, columnKey)).diff(moment(get(b, columnKey)));
          });
        } else {
          newItems = items.slice(0).sort();
        }
        break;
      default:
        newItems = items.slice(0).sort();
    }
    if (!isSortedDescending) newItems.reverse();
    return newItems;
  }

  return (
    <ShimmeredDetailsList
      compact={true}
      items={currentItems}
      columns={columns}
      getKey={otherProps.selection === undefined ? (item: any, i) => item?.id ?? i : undefined}
      setKey="none"
      onRenderRow={onRenderRow}
      selectionMode={SelectionMode.none} // No selection by default
      selectionPreservedOnEmptyClick={true}
      layoutMode={DetailsListLayoutMode.justified}
      constrainMode={ConstrainMode.unconstrained}
      onRenderDetailsHeader={onRenderDetailsHeader}
      onRenderDetailsFooter={(detailsFooterProps?: IDetailsFooterProps) =>
        footer && onRenderDetailsFooter(footer, detailsFooterProps)
      }
      onActiveItemChanged={props.onActiveItemChanged}
      onColumnHeaderClick={onColumnClick}
      enableShimmer={isLoading}
      detailsListStyles={{
        ...(useNativeSticky
          ? {
              headerWrapper: {
                position: "sticky",
                top: 0,
                left: 0,
                zIndex: 1,
              },
            }
          : {}),
        ...otherProps.styles,
      }}
      // By default FluintUI does not support subproperty selection. With the custom get function, subproperty selection is enabled.
      onRenderItemColumn={props.onRenderItemColumn ?? ((item, _, column) => get(item, column?.fieldName))}
      {...otherProps}
    />
  );
};

export default UniformDetailsList;
