import { AxiosRequestConfig } from "axios";
import useAxios, { Options } from "axios-hooks";

import { buildParams } from "../../../util/utils";

import { ReportsQueryString } from "./types";

interface Params {
  url?: string;
  queryString?: ReportsQueryString;
  manual?: boolean;
  config?: AxiosRequestConfig;
  options?: Options;
}

export function useReports<T>({ url, queryString, manual, config, options }: Params) {
  const [{ data, loading, error }, refetch] = useAxios<T>(
    {
      url,
      params: queryString,
      paramsSerializer: buildParams,
      ...config,
    },
    { manual, ...options },
  );

  if (error !== null) throw error;

  return {
    data,
    loading,
    refetch,
  };
}
