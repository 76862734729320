import { FC, createContext, useEffect } from "react";

import { useAuthentication } from "./AuthenticationContext";

interface ContextInterface {}

const UserGuidingContext = createContext<ContextInterface>({});

const UserGuidingProvider: FC = ({ children }) => {
  const { decodedToken } = useAuthentication();

  useEffect(() => {
    if (decodedToken === null || decodedToken === undefined) return;
    // @ts-expect-error window isn't aware that userGuiding is loaded from the index.html
    window.onUserGuidingLoad = () => {
      // @ts-expect-error window isn't aware that userGuiding is loaded from the index.html
      window.userGuiding.identify(decodedToken.sub, {
        email: decodedToken.email,
      });
    };
  }, [decodedToken]);

  const defaultContext: ContextInterface = {};

  return <UserGuidingContext.Provider value={defaultContext}>{children}</UserGuidingContext.Provider>;
};

export default UserGuidingProvider;
