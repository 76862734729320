import useAxios from "axios-hooks";
import { useMemo } from "react";

import { formatCentToDecimal } from "../../../util/numbers";

import { AdjustingEntry, AdjustingEntryResponse } from "./types";

interface Params {
  entityId?: string;
  manual?: boolean;
}

const useAdjustingEntries = ({ entityId, manual }: Params) => {
  const [{ data, error, loading }] = useAxios<AdjustingEntryResponse[]>(
    {
      url: `/entities/${entityId}/adjusting-entries`,
    },
    { manual: entityId === undefined || manual },
  );

  const adjustingEntries = useMemo(() => {
    return data?.map<AdjustingEntry>(adjEnt => ({ ...adjEnt, amount: formatCentToDecimal(adjEnt.amount) }));
  }, [data]);

  if (error !== null) throw error;

  return {
    adjustingEntries,
    loading,
  };
};

export default useAdjustingEntries;
