import { DefaultButton, IButtonProps, IButtonStyles, concatStyleSets, customizable, nullRender } from "@fluentui/react";
import * as React from "react";

@customizable("DangerousButton", ["theme", "styles"], true)
class DangerousButton extends React.Component<IButtonProps, {}> {
  public render(): JSX.Element {
    const { primary = true, styles, theme } = this.props;

    const dangerousStyles: IButtonStyles = {
      root: {
        color: "#fff",
        border: theme?.semanticColors.primaryButtonBorder,
        backgroundColor: theme?.palette.red,
      },
      rootHovered: {
        color: "#fff",
        border: theme?.semanticColors.primaryButtonBorder,
        backgroundColor: theme?.palette.redDark,
      },
      rootPressed: {
        color: "#fff",
        border: theme?.semanticColors.primaryButtonBorder,
        backgroundColor: theme?.palette.redDark, // TODO: Darken this
      },
    };

    return (
      <DefaultButton
        {...this.props}
        styles={concatStyleSets(theme!, styles, primary, dangerousStyles)}
        onRenderDescription={nullRender}
      />
    );
  }
}

export default DangerousButton;
