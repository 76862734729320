import "@journyio/web-types/dist";
import { useFeature } from "flagged";
import { FC, createContext, useContext, useEffect } from "react";

import { useProfile } from "../components/hooks/useProfile";

import { useAuthentication } from "./AuthenticationContext";
import { useDashboardContext } from "./DashboardContext";

interface ContextInterface {
  logScreen: (screenName: string) => void;
  logEvent: (name: string, metadata?: JournyIO_.Metadata) => void;
}

const JournyContext = createContext<ContextInterface>({
  logScreen: () => {},
  logEvent: () => {},
});

export const JournyProvider: FC = ({ children }) => {
  const { selectedOrganisation, organisations } = useDashboardContext();
  const { decodedToken } = useAuthentication();
  const organisation = organisations?.find(o => o.id === selectedOrganisation);
  const enableJourny = useFeature("enableJourny");
  const { user } = useProfile();

  function logScreen(screenName: string) {
    if (enableJourny) journy("screen", { name: screenName, accountId: organisation?.id });
  }

  function logEvent(name: string, metadata?: JournyIO_.Metadata) {
    if (enableJourny) journy("event", { name, accountId: organisation?.id, metadata });
  }

  useEffect(() => {
    if (decodedToken && organisation && user) {
      if (enableJourny) {
        journy("identify", {
          userId: decodedToken.sub,
          email: decodedToken.email,
          properties: {
            first_name: user.firstName,
            last_name: user.lastName,
            full_name: `${user.firstName} ${user.lastName}`,
          },
        });
        journy("account", { accountId: organisation.id, properties: { name: organisation.name } });
      }
    }
  }, [decodedToken, enableJourny, organisation, user]);

  const defaultContext: ContextInterface = {
    logScreen,
    logEvent,
  };

  return <JournyContext.Provider value={defaultContext}>{children}</JournyContext.Provider>;
};

export function useJourny(): ContextInterface {
  return useContext(JournyContext);
}
