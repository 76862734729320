import { mergeStyles } from "@fluentui/react";
import { ICardProps } from "@fluentui/react-cards";
import { ComponentsStyles } from "@fluentui/theme";

import { monitrLightTheme } from "../theme";

import MonitrBaseComponents from "./base";

const monitrBaseComponents = MonitrBaseComponents(monitrLightTheme);

export const monitrLightComponents: ComponentsStyles = {
  ...monitrBaseComponents,
  // Custom shimmer gradient
  ShimmeredDetailsList: {
    styles: {
      root: {
        selectors: {
          ":after": {
            backgroundImage: `linear-gradient(to bottom, ${monitrLightTheme.semanticColors.bodyBackground}00 30%, ${monitrLightTheme.semanticColors.bodyBackground}66 65%,${monitrLightTheme.semanticColors.bodyBackground} 100%)`,
          },
        },
      },
    },
  },
  Card: {
    styles: {
      root: mergeStyles({
        background: monitrLightTheme.semanticColors.bodyBackground,
        ...(monitrBaseComponents.Card.styles.root as Object),
      }),
    },
  } as Partial<ICardProps>,
};
