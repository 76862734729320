import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDashboardContext } from "../../../context/DashboardContext";
import EmptyState from "../EmptyState";

interface Props {
  consolidation?: boolean;
  children: JSX.Element | JSX.Element[];
}

const ConsolidationCheck = (props: Props) => {
  const { t } = useTranslation(["organisation_settings"]);
  const { updateConsolidationState, organisations, selectedOrganisation } = useDashboardContext();
  const [isActive, setIsActive] = useState<boolean>(false);
  const organisation = organisations?.find(o => o.id === selectedOrganisation);

  useEffect(() => {
    const check = !!props.consolidation && !!organisation?.settings?.consolidation?.is_active;
    updateConsolidationState(check);
    setIsActive(check);
  }, [setIsActive, props.consolidation, organisation?.settings?.consolidation?.is_active, updateConsolidationState]);

  if (!isActive)
    return (
      <EmptyState
        title={t("consolidation.not_available_empty_state.title")}
        explainer={t("consolidation.not_available_empty_state.explainer")}
        cta={t("consolidation.not_available_empty_state.cta")}
        url={"/dashboard/organisations/settings"}
      />
    );
  return <>{props.children}</>;
};

export default ConsolidationCheck;
