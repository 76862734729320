import {
  FontIcon,
  IconButton,
  Image,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TooltipHost,
  useTheme,
} from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import axios from "axios";
import useAxios from "axios-hooks";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDashboardContext } from "../../../../../../context/DashboardContext";
import { SubscriptionTier } from "../../../../../../types/organisation";
import AreYouSure from "../../../../../atoms/AreYouSure";
import useStripe from "../../../../../hooks/useStripe";
import SubscriptionButton from "../../../../../molecules/SubscriptionButton";
import { DeleteOrganisationBody, DeleteOrganisationResponse } from "../types";

import { PaymentInfoResponse } from "./types";

interface Props {
  payingForYourself: boolean;
}

const PaymentStatus: FC<Props> = ({ payingForYourself }) => {
  const theme = useTheme();
  const { t } = useTranslation(["billing"]);
  const [openAreYouSureDeletePaying, setOpenAreYouSureDeletePaying] = useState<boolean>(false);

  const { selectedOrganisation, organisations, refetchOrganisations } = useDashboardContext();
  const organisation = organisations.find(o => o.id === selectedOrganisation);
  const paymentIsOk =
    organisation === undefined ? false : ["trialing", "active"].includes(organisation.subscriptionHolder.paymentState);
  const paymentColor = paymentIsOk ? theme.palette.green : theme.palette.redDark;
  const payingOrganisation = organisations.find(o => o.id === organisation?.subscriptionHolder.organisationId);
  const isAdminOnPayingOrg =
    payingOrganisation === undefined
      ? false
      : payingOrganisation.permissions.includes("organisation.organisation_data:read");

  const { createCustomerPortalSession } = useStripe({ organisationId: selectedOrganisation });

  const [{ data, error, loading }, getStripeInfo] = useAxios<PaymentInfoResponse>(
    {
      url: `/organisations/${organisation?.id}/stripe-info`,
    },
    {
      manual: organisation === undefined,
    },
  );
  const [{ error: updatePaidOrganisationError }, updatePaidOrganisation] = useAxios<
    DeleteOrganisationResponse,
    DeleteOrganisationBody
  >(
    {
      method: "PUT",
    },
    { manual: true },
  );

  if (error) throw error;
  if (updatePaidOrganisationError) throw updatePaidOrganisationError;
  if (organisation === undefined) return null;

  return (
    <Card tokens={{ padding: theme.spacing.l1 }} styles={{ root: { maxWidth: "100%", width: "100%" } }}>
      <Card.Item>
        <Stack tokens={{ childrenGap: theme.spacing.l1 }}>
          <Stack horizontal tokens={{ childrenGap: theme.spacing.l2 }}>
            <Stack.Item grow>
              <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
                <Text variant="xLarge">{t("paymentStatus.title", { ns: "billing" })}</Text>
                <FontIcon
                  style={{ fontSize: theme.fonts.xLarge.fontSize, color: paymentColor }}
                  iconName={paymentIsOk ? "SkypeCircleCheck" : "StatusErrorFull"}
                />
              </Stack>
            </Stack.Item>
            {isAdminOnPayingOrg && organisation.subscriptionHolder.paymentState !== "paused" && (
              <Stack.Item>
                <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
                  <TooltipHost delay={0} content={t("paymentStatus.cta", { ns: "billing" })}>
                    <IconButton
                      iconProps={{ iconName: "PaymentCard" }}
                      styles={{
                        root: {
                          padding: 0,
                          border: `1px solid ${theme.semanticColors.buttonBorder}`,
                        },
                      }}
                      onClick={async () => {
                        const sessionUrl = await createCustomerPortalSession();
                        window.open(sessionUrl.data, "_blank");
                      }}
                    />
                  </TooltipHost>
                  <SubscriptionButton
                    text={
                      organisation.subscriptionHolder.paymentState === "trialing" &&
                      organisation.tier === SubscriptionTier.Lite
                        ? t("paymentStatus.try", { ns: "billing" })
                        : organisation.tier === SubscriptionTier.Premium
                          ? t("paymentStatus.downgrade", { ns: "billing" })
                          : t("paymentStatus.upgrade", { ns: "billing" })
                    }
                    iconName={organisation.tier === SubscriptionTier.Lite ? "Diamond" : "Down"}
                    tier={organisation.tier}
                    onClick={async () => {
                      await axios.patch(`/organisations/${organisation?.id}/monitr-subscription`, {
                        tier:
                          organisation.tier === SubscriptionTier.Lite
                            ? SubscriptionTier.Premium
                            : SubscriptionTier.Lite,
                      });
                      await refetchOrganisations();
                      await getStripeInfo();
                    }}
                    ignoreAreYouSure={organisation.subscriptionHolder.paymentState === "trialing"}
                  />
                </Stack>
              </Stack.Item>
            )}
          </Stack>
          <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.m }} verticalAlign="center">
              <Text variant="mediumPlus" styles={{ root: { fontWeight: 600, width: 175 } }}>
                {t("paymentStatus.subscriptionTier", { ns: "billing" })}
              </Text>
              <Separator vertical />
              <Image
                src={
                  organisation?.tier === SubscriptionTier.Premium
                    ? "/images/monitr-premium.svg"
                    : "/images/monitr-lite.svg"
                }
                height={25}
              />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.m }} verticalAlign="center">
              <Text variant="mediumPlus" styles={{ root: { fontWeight: 600, width: 175 } }}>
                {t("paymentStatus.payingOrganisation", { ns: "billing" })}
              </Text>
              <Separator vertical />
              {loading ? (
                <Spinner size={SpinnerSize.small} />
              ) : (
                <Stack horizontal tokens={{ childrenGap: theme.spacing.s2 }} verticalAlign="center">
                  <Text variant="mediumPlus">{data?.organisationName ?? ""}</Text>
                  {!payingForYourself && (
                    <TooltipHost
                      content={t("paymentStatus.deletePayer.tooltip", { ns: "billing", name: data?.organisationName })}
                    >
                      <IconButton
                        iconProps={{ iconName: "Delete" }}
                        styles={{
                          root: { color: theme.palette.red },
                          rootHovered: { color: theme.palette.red, backgroundColor: theme.palette.neutralLight },
                        }}
                        onClick={() => setOpenAreYouSureDeletePaying(true)}
                      />
                    </TooltipHost>
                  )}
                </Stack>
              )}
            </Stack>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.m }} verticalAlign="center">
              <Text variant="mediumPlus" styles={{ root: { fontWeight: 600, width: 175 } }}>
                {t("paymentStatus.stripeEmail", { ns: "billing" })}
              </Text>
              <Separator vertical />
              {!loading && <Text variant="mediumPlus">{data?.stripeEmail ?? ""}</Text>}
            </Stack>
          </Stack>
        </Stack>
        {openAreYouSureDeletePaying && (
          <AreYouSure
            title={t("paymentStatus.deletePayer.areYouSure.title", { ns: "billing", name: data?.organisationName })}
            explainer={t("paymentStatus.deletePayer.areYouSure.explainer", {
              ns: "billing",
              name: data?.organisationName,
            })}
            onApproval={async () => {
              await updatePaidOrganisation({
                url: `/organisations/${organisation.id}/organisations-subscription-holder`,
                data: { orgId: organisation.id },
              });
              await refetchOrganisations();
              await getStripeInfo();
              setOpenAreYouSureDeletePaying(false);
            }}
            onDismiss={() => setOpenAreYouSureDeletePaying(false)}
            isDangerous
            customWidth={400}
          />
        )}
      </Card.Item>
    </Card>
  );
};

export default PaymentStatus;
