import { Integration } from "./integration";

// API response
export interface EntitiesResponse<T = Entity> {
  entities: T[];
}

export enum EntityStatus {
  Active = "active",
  NotFound = "not_found",
  Deleted = "deleted",
}

export enum EntityOnboardingStatus {
  NotStarted = "not_started",
  Dates = "dates",
  Reporting = "reporting",
  Mapping = "mapping",
  Dashboard = "dashboard",
  Done = "done",
}

export enum Currency {
  EUR = "EUR",
  USD = "USD",
  GBP = "GBP",
  AUD = "AUD",
  SEK = "SEK",
  BRL = "BRL",
}

export interface Entity {
  id: string;
  code: string;
  name: string;
  ytd_start: string;
  ytg_end: string;
  axis: string | null;
  last_date_actuals: string;
  budget_account_id_receivable: string;
  budget_account_id_payable: string;
  organisation_id: string;
  status: EntityStatus;
  onboarding_status: EntityOnboardingStatus;
  currency: Currency;
  vat_number: string;
  settings: {
    exactImport?: object;
  };
  created_at: string;
  integration?: Integration;
  integrations?: Integration[];
  country_code: string;
}

export type EntityPermission =
  | "entity.core:read"
  | "entity.core:write"
  | "entity.receivables:read"
  | "entity.payables:read"
  | "entity.reports:read"
  | "entity.general_ledger:read"
  | "entity.entity_data:read"
  | "entity.entity_data:write";

export interface EntityWithPermissions {
  id: string;
  permissions: EntityPermission[];
}
