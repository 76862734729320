import { FontIcon, PrimaryButton, Stack, Text, useTheme } from "@fluentui/react";
import { CSSProperties, FC } from "react";
import { Link as RouterLink } from "react-router-dom";

interface Props {
  title: string;
  explainer: string;
  cta?: string;
  url?: string;
  iconName?: string;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
}

const EmptyState: FC<Props> = ({ title, explainer, cta, url, iconName, style, className, onClick }) => {
  const theme = useTheme();

  return (
    <Stack
      verticalFill
      verticalAlign="center"
      horizontalAlign="center"
      className={className}
      style={{ padding: theme.spacing.l1, maxWidth: 500, margin: "0 auto", ...style }}
    >
      <FontIcon
        iconName={iconName || "ChatBot"}
        style={{ display: "block", fontSize: 150, textAlign: "center", color: theme.palette.neutralLight }}
      />
      <Text block styles={{ root: { padding: 25, textAlign: "center" } }} variant="xLarge">
        {title}
      </Text>
      <Text block>{explainer}</Text>
      <Stack.Item align="center">
        {cta && url ? (
          <RouterLink to={url}>
            <PrimaryButton styles={{ root: { height: 45, marginTop: 15 } }}>{cta}</PrimaryButton>
          </RouterLink>
        ) : cta ? (
          <PrimaryButton styles={{ root: { height: 45, marginTop: 15 } }} onClick={onClick}>
            {cta}
          </PrimaryButton>
        ) : null}
      </Stack.Item>
    </Stack>
  );
};

export default EmptyState;
