import { Stack, getTheme } from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import { FC } from "react";

import UpsellContent from "./UpsellContent";

const UpsellScreen: FC = () => {
  const theme = getTheme();
  return (
    <Stack
      styles={{
        root: {
          width: "100%",
          height: "100%",
          backgroundImage: 'url("/images/blurred-upsell.png")',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        },
      }}
      verticalAlign="center"
      horizontalAlign="center"
    >
      <Card
        styles={{
          root: {
            background: theme.semanticColors.bodyBackground,
            padding: theme.spacing.l2,
            maxWidth: 700,
            height: "fit-content",
          },
        }}
      >
        <UpsellContent />
      </Card>
    </Stack>
  );
};

export default UpsellScreen;
