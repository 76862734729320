import { FontIcon, Stack, Text, useTheme } from "@fluentui/react";
import { FC } from "react";

interface Props {
  color: string;
  text: string;
}

const BulletPoint: FC<Props> = ({ color, text }) => {
  const theme = useTheme();
  return (
    <Stack horizontal tokens={{ childrenGap: theme.spacing.l2 }}>
      <FontIcon iconName="SkypeCircleCheck" style={{ color }} />
      <Text>{text}</Text>
    </Stack>
  );
};

export default BulletPoint;
