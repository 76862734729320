import { EntityPermission } from "./entity";

type OrganisationPermission =
  | "organisation.organisation_data:read"
  | "organisation.organisation_data:write"
  | "organisation.teams:read"
  | "organisation.teams:write"
  | "organisation.reports:read"
  | "organisation.general_ledger:read";

export type Permission = OrganisationPermission | EntityPermission;

export enum OrganisationType {
  Accountant = "accountant",
  Business = "business",
}

export enum SubscriptionTier {
  Lite = "lite",
  Premium = "premium",
}

interface SubscriptionHolder {
  id: string;
  paymentProviderCustomerId: string;
  paymentState: string;
  paymentStateUpdatedAt: string;
  trialPeriodEndDate: string;
  organisationId: string;
  tier: SubscriptionTier;
}

export type OrganisationConsolidationRelations = Record<string, Record<string, string[]>>;
export interface Organisation {
  id: string;
  name: string;
  vat_number: string | null;
  settings: {
    consolidation?: {
      is_active: boolean;
      relations: OrganisationConsolidationRelations;
    };
  };
  organisation_type: OrganisationType;
  created_at: string;
  updated_at: string;
  permissions: OrganisationPermission[];
  subscriptionHolder: SubscriptionHolder;
  tier: SubscriptionTier;
}
