import { ISemanticColors, PartialTheme, createTheme } from "@fluentui/react";
import { DefaultTheme } from "@fluentui/theme-samples";

// Register custom icons
import "./icons";

// Default theme (light)
const currentTheme = DefaultTheme;

const _baseTheme: PartialTheme = {
  // defaultFontStyle: {
  //   // fontSize: 14,
  //   // lineHeight: 22,
  //   // fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
  // },
};

// Extend with custom stuff
const _monitrLightTheme: PartialTheme = {
  ..._baseTheme,
  palette: {
    themePrimary: "#193153",
    themeLighterAlt: "#f1f4f8",
    themeLighter: "#cad5e4",
    themeLight: "#a1b3cc",
    themeTertiary: "#587398",
    themeSecondary: "#284369",
    themeDarkAlt: "#172d4c",
    themeDark: "#132640",
    themeDarker: "#0e1c2f",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#8ca1ba",
    neutralSecondary: "#6e87a3",
    neutralPrimaryAlt: "#536e8d",
    neutralPrimary: "#0e1f33",
    neutralDark: "#294360",
    black: "#1a3049",
    white: "#ffffff",
  },
  semanticColors: {
    inputBackgroundChecked: "#FADB0F",
    primaryButtonBackground: "#FADB0F",
    primaryButtonBackgroundHovered: "#eecf04",
    primaryButtonBackgroundPressed: "#caaf03",
    primaryButtonText: currentTheme.semanticColors.bodyText,
    primaryButtonTextHovered: currentTheme.semanticColors.bodyText,
    primaryButtonTextPressed: currentTheme.semanticColors.bodyText,
  } as Partial<ISemanticColors & { [key: string]: string }>,
};

export const monitrLightTheme = createTheme(_monitrLightTheme);
