import axios from "axios";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { FC, createContext, useContext, useEffect, useMemo, useState } from "react";

import { useDashboardContext } from "../../../../../../context/DashboardContext";
import { useEntityContext } from "../../../../../../context/EntityContext";
import { ReportPeriod, SetState } from "../../../../../../types/common";
import { SubscriptionTier } from "../../../../../../types/organisation";
import { useBudgetVersions } from "../../../../../hooks/useBudgetVersions";
import { BudgetVersion, GlobalBudgetType } from "../../../../../hooks/useBudgetVersions/types";
import { Forecast, Scenario } from "../../../../../hooks/useForecasts/types";
import {
  getDefaultScenarioBudgetVersionIdsByBudgetVersionIds,
  getDefaultScenarioBudgetVersionIdsByBudgetVersionIdsConsolidation,
} from "../../../Reports/util";

import { Props } from "./types";
const moment = extendMoment(Moment);

interface ContextInterface {
  // Shared state
  period: ReportPeriod;
  setPeriod: SetState<ReportPeriod>;
  scenarioId?: string;
  setScenarioId: SetState<string | undefined>;
  scenarioIds?: string[];
  setScenarioIds: SetState<string[] | undefined>;
  budgetVersionIds?: string[];
  comparisonBudgetVersionIds?: string[];
  forecasts?: Forecast[];
  globalBudgetVersions?: BudgetVersion[];
  loading: boolean;
  consolidation: boolean;
}

const ExportContext = createContext<ContextInterface>({
  period: {
    start: moment().startOf("year").format("YYYY-MM-DD"),
    lda: moment().startOf("year").format("YYYY-MM-DD"),
    end: moment().endOf("year").format("YYYY-MM-DD"),
  },
  setPeriod: () => {},
  setScenarioId: () => {},
  setScenarioIds: () => {},
  loading: false,
  consolidation: false,
});

export const ExportProvider: FC<Props> = ({ consolidation, children }) => {
  const { organisations, selectedOrganisation } = useDashboardContext();
  const organisation = organisations.find(o => o.id === selectedOrganisation);
  const { selectedEntity, entities, selectedScenarioId } = useEntityContext();
  const entity = entities.find(e => e.id === selectedEntity);

  const [period, setPeriod] = useState<ReportPeriod>({
    start: entity?.ytd_start ?? moment().startOf("year").format("YYYY-MM-DD"),
    lda:
      organisation?.tier === SubscriptionTier.Premium
        ? (entity?.last_date_actuals ?? moment().startOf("year").format("YYYY-MM-DD"))
        : (entity?.ytg_end ?? moment().endOf("year").format("YYYY-MM-DD")),
    end: entity?.ytg_end ?? moment().endOf("year").format("YYYY-MM-DD"),
  });
  const [scenarioId, setScenarioId] = useState<string | undefined>(selectedScenarioId);
  const [scenarioIds, setScenarioIds] = useState<string[] | undefined>();
  const [forecasts, setForecasts] = useState<Forecast[]>();
  const [forecastsLoading, setForecastsLoading] = useState<boolean>(false);

  const { budgetVersions: globalBudgetVersions, loading: globalBudgetVersionsLoading } = useBudgetVersions({
    entityId: selectedEntity,
    budgetType: Object.values(GlobalBudgetType),
    manual: consolidation || organisation?.tier !== SubscriptionTier.Premium,
  });

  useEffect(() => {
    if (organisation?.tier !== SubscriptionTier.Premium) return;
    if (entities.length === 0) return;
    const entitiesToFetch = consolidation
      ? entities.map(e => e.id)
      : selectedEntity === undefined
        ? []
        : [selectedEntity];

    async function fetchForecasts(entitiesToFetch: string[]) {
      setForecastsLoading(true);
      const allForecasts: Forecast[] = [];
      for (const entId of entitiesToFetch) {
        const { data } = await axios.get<Forecast[]>(`/entities/${entId}/forecasts`);
        allForecasts.push(...data);
      }
      setForecasts(allForecasts);
      setForecastsLoading(false);
    }

    fetchForecasts(entitiesToFetch);
  }, [consolidation, entities, selectedEntity, organisation?.tier]);

  const loading = forecastsLoading || globalBudgetVersionsLoading;

  useEffect(() => {
    if (scenarioId !== undefined) return;
    if (forecasts === undefined) return;
    if (consolidation) return;

    const firstScenarioInReportingPeriod = forecasts
      .filter(f =>
        f.scenarios
          .flatMap(s => s.budgetVersions)
          .find(bv => {
            if (period === undefined) return false;
            if (bv?.settings.startDate === undefined || bv?.settings.endDate === undefined) return false;
            return moment
              .range(moment(period.start), moment(period.end))
              .overlaps(moment.range(moment(bv.settings.startDate), moment(bv.settings.endDate)));
          }),
      )
      .at(0)
      ?.scenarios.find(s => s.active);

    setScenarioId(firstScenarioInReportingPeriod?.id ?? forecasts.at(0)?.scenarios.find(s => s.active)?.id);
  }, [forecasts, period, scenarioId, setScenarioId, consolidation]);

  useEffect(() => {
    if (!consolidation || forecasts === undefined || forecasts.length === 0) return;
    const scenariosByEntityId = forecasts.reduce<Record<string, Scenario>>((res, fc) => {
      fc.scenarios.forEach(sc => {
        const prevScenario = res[fc.entityId];
        if (sc.active && (prevScenario === undefined || sc.createdAt > prevScenario.createdAt)) res[fc.entityId] = sc;
      });
      return res;
    }, {});
    setScenarioIds(Object.values(scenariosByEntityId).map(sc => sc.id));
  }, [forecasts, consolidation]);

  const [budgetVersionIds, comparisonBudgetVersionIds] = useMemo(() => {
    if (forecasts === undefined) return [undefined, undefined];

    if (consolidation) {
      if (scenarioIds === undefined) return [undefined, undefined];
      const budVerIds = forecasts
        .flatMap(f => f.scenarios ?? [])
        .filter(sc => scenarioIds.includes(sc.id))
        .flatMap(sc => sc.budgetVersions ?? [])
        .map(bv => bv.id);
      return [budVerIds, getDefaultScenarioBudgetVersionIdsByBudgetVersionIdsConsolidation(forecasts, budVerIds)];
    } else {
      if (scenarioId === undefined) return [undefined, undefined];
      if (globalBudgetVersions === undefined) return [undefined, undefined];

      const selectedScenario = forecasts.flatMap(f => f.scenarios).find(s => s.id === scenarioId);
      const budVerIds = [
        ...(selectedScenario?.budgetVersions?.map(bv => bv.id) ?? []),
        ...(globalBudgetVersions?.map(bv => bv.id) ?? []),
      ];
      return [budVerIds, getDefaultScenarioBudgetVersionIdsByBudgetVersionIds(forecasts, budVerIds)];
    }
  }, [forecasts, consolidation, scenarioIds, scenarioId, globalBudgetVersions]);

  const defaultContext: ContextInterface = {
    period,
    setPeriod,
    scenarioId,
    setScenarioId,
    scenarioIds,
    setScenarioIds,
    budgetVersionIds,
    comparisonBudgetVersionIds,
    forecasts,
    loading,
    globalBudgetVersions,
    consolidation,
  };

  return <ExportContext.Provider value={defaultContext}>{children}</ExportContext.Provider>;
};

export function useExportContext(): ContextInterface {
  return useContext(ExportContext);
}
